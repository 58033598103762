import firebase from "@firebase/app"
import "firebase/auth"
import "firebase/database"
import "firebase/storage"

let config = {
    apiKey: "AIzaSyCFnULrBpWHeHDakFqnb4HYZB6uCr_bks8",
    authDomain: "interviewpass-220306.firebaseapp.com",
    databaseURL: "https://interviewpass-220306.firebaseio.com",
    projectId: "interviewpass-220306",
    storageBucket: "interviewpass-220306.appspot.com",
    messagingSenderId: "370413117769"
  };

const firebaseRef = firebase.initializeApp(config);

export const authRef = firebase.auth();
export const storageRef = firebase.storage().ref();
export const storage = firebase.storage();
export const GOOGLE_AUTH_PROVIDER = firebase.auth.GoogleAuthProvider.PROVIDER_ID;
export const EMAIL_AUTH_PROVIDER = firebase.auth.EmailAuthProvider.PROVIDER_ID;

export default firebaseRef;