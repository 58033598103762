export const starConstants = {
    CREATE_STAR_REQUEST: 'CREATE_STAR_REQUEST',
    CREATE_STAR_SUCCESS: 'CREATE_STAR_SUCCESS',
    CREATE_STAR_FAILURE: 'CREATE_STAR_FAILURE',
    FETCH_STARS_REQUEST: 'FETCH_STARS_REQUEST',
    FETCH_STARS_SUCCESS: 'FETCH_STARS_SUCCESS',
    FETCH_STARS_FAILURE: 'FETCH_STARS_FAILURE',
    FETCH_APPLICATION_STARS_REQUEST: 'FETCH_APPLICATION_STARS_REQUEST',
    FETCH_APPLICATION_STARS_SUCCESS: 'FETCH_APPLICATION_STARS_SUCCESS',
    FETCH_APPLICATION_STARS_FAILURE: 'FETCH_APPLICATION_STARS_FAILURE',
    CREATE_APPLICATION_STAR_REQUEST: 'CREATE_APPLICATION_STAR_REQUEST',
    CREATE_APPLICATION_STAR_SUCCESS: 'CREATE_APPLICATION_STAR_SUCCESS',
    CREATE_APPLICATION_STAR_FAILURE: 'CREATE_APPLICATION_STAR_FAILURE',
}