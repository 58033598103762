import axios from 'axios';
import {SCORE_URL} from '../constants/urlConstants';
import authHeader from '../helpers/authHeader';

const createScore = (response_id, job_application_uid, attribute_id, score, at_response_level) => {
    return axios.post(SCORE_URL, {response_id, job_application_uid, attribute_id, score, at_response_level}, authHeader())
            .then(response=>{
                return response.data
            })
}

const fetchScores = (response_id) => {
    return axios.get(SCORE_URL, authHeader({response_id}))
        .then(response => {
            return response.data;
        })
}

const fetchScoresForApplication = (job_application_uid) => {
    return axios.get(SCORE_URL, authHeader({job_application_uid}))
        .then(response => {
            return response.data;
        })
}

const createScoreForApplication = (job_application_uid, attribute_id, score) => {
    return axios.post(SCORE_URL, {job_application_uid, attribute_id, score}, authHeader())
            .then(response=>{
                return response.data
            })
}

export const scoreService = {
    createScore,
    fetchScores,
    fetchScoresForApplication,
    createScoreForApplication,
}