import { noteConstants } from '../constants';

// let user = JSON.parse(localStorage.getItem('user'));
const initialState = {notes:[], selected_response_id: null};

export const noteReducer = (state = initialState, action) => {
    switch (action.type) {
    case noteConstants.CREATE_APPLICATION_NOTE_SUCCESS:
    case noteConstants.CREATE_ASSESSMENT_SUBMISSION_NOTE_SUCCESS:
    case noteConstants.CREATE_NOTE_SUCCESS:
        return Object.assign({}, state, {
            notes: state.notes ? state.notes.concat(action.note) : [action.note]
        });
    case noteConstants.FETCH_APPLICATION_NOTES_SUCCESS:
    case noteConstants.FETCH_ASSESSMENT_SUBMISSION_NOTES_SUCCESS:
    case noteConstants.FETCH_NOTES_SUCCESS:
        return Object.assign({}, state, {
            notes: action.notes
        });
    case noteConstants.SET_SELECTED_RESPONSE:
        return Object.assign({}, state, {
            selected_response_id: action.data.response_id
        })
    default:
        return state
    }
}