import React, { Component, Fragment } from 'react';
import { Layout } from 'antd';
import NavBar from '../NavBar';
import {Row, Col, Container} from 'react-bootstrap';
const { Footer } = Layout;

export default class PrivateLayout extends Component{

    componentDidMount(){
        this.props.verifyAuthToken();
    }

    getActiveKey(){
        let url = window.location.href;
        if(url.indexOf('assessments') !== -1){
            return "assessments"
        }else if(url.indexOf('interviews') !== -1){
            return "interviews"
        }else if(url.indexOf('settings') !== -1){
            return "settings"
        }
        return ''
    }
    
    render(){
        let {children} = this.props;
        return(
            <Fragment style={{background: '#F4F5FB' }}>
                <NavBar />
                <Container fluid={true}>
                    <Row style={{ padding: '24px 0', margin:'24px 0', background: '#fff',  border: "1px solid #d7d7d8"}}>
                        <Col>
                            {children}
                        </Col>
                    </Row>
                </Container>
                <Footer style={{position: "relative", textAlign: "center",width: "100%", bottom: 0}}>©2019 Created by InterviewPass</Footer>
            </Fragment>
        )
    }
}