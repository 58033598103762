import React from 'react';

export default function Loading({ isLoading, pastDelay, error }) {
    if (isLoading && pastDelay) {
        return <div className="asset-loader"></div>;
    } else if (error && !isLoading) {
        console.log(error);
        return <p clasName="mx-auto">Error!</p>;
    } else {
        return null;
    }
}