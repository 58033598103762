import axios from 'axios';
import {NOTES_URL} from '../constants/urlConstants';
import authHeader from '../helpers/authHeader';

const createNote = (text, response_id) => {
    return axios.post(NOTES_URL, {text, response_id}, authHeader())
            .then(response=>{
                return response.data
            })
}

const createNoteForApplication = (text, job_application_uid) => {
    return axios.post(NOTES_URL, {text, job_application_uid}, authHeader())
            .then(response=>{
                return response.data
            })
}

const createNoteForAssessmentSubmission = (text, assessment_submission_uid) => {
    return axios.post(NOTES_URL, {text, assessment_submission_uid}, authHeader())
            .then(response=>{
                return response.data
            })
}

const fetchNotes = (response_id) => {
    return axios.get(NOTES_URL, authHeader({response_id}))
        .then(response => {
            return response.data;
        })
}

const fetchNotesForApplication = (job_application_uid) => {
    return axios.get(NOTES_URL, authHeader({job_application_uid}))
        .then(response => {
            return response.data;
        })
}

const fetchNotesForAssessmentSubmission = (assessment_submission_uid) => {
    return axios.get(NOTES_URL, authHeader({assessment_submission_uid}))
        .then(response => {
            return response.data;
        })
}

export const noteService = {
    createNote,
    fetchNotes,
    fetchNotesForApplication,
    createNoteForApplication,
    createNoteForAssessmentSubmission,
    fetchNotesForAssessmentSubmission,
}