export const reviewConstants = {
    FETCH_APPLICATION_REVIEWS_REQUEST: 'FETCH_APPLICATION_REVIEWS_REQUEST',
    FETCH_APPLICATION_REVIEWS_SUCCESS: 'FETCH_APPLICATION_REVIEWS_SUCCESS',
    FETCH_APPLICATION_REVIEWS_FAILURE: 'FETCH_APPLICATION_REVIEWS_FAILURE',
    CREATE_APPLICATION_REVIEW_REQUEST: 'CREATE_APPLICATION_REVIEW_REQUEST',
    CREATE_APPLICATION_REVIEW_SUCCESS: 'CREATE_APPLICATION_REVIEW_SUCCESS',
    CREATE_APPLICATION_REVIEW_FAILURE: 'CREATE_APPLICATION_REVIEW_FAILURE',
    FETCH_ASSESSMENT_SUBMISSION_REVIEWS_REQUEST: 'FETCH_ASSESSMENT_SUBMISSION_REVIEWS_REQUEST',
    FETCH_ASSESSMENT_SUBMISSION_REVIEWS_SUCCESS: 'FETCH_ASSESSMENT_SUBMISSION_REVIEWS_SUCCESS',
    FETCH_ASSESSMENT_SUBMISSION_REVIEWS_FAILURE: 'FETCH_ASSESSMENT_SUBMISSION_REVIEWS_FAILURE',
    CREATE_ASSESSMENT_SUBMISSION_REVIEW_REQUEST: 'CREATE_ASSESSMENT_SUBMISSION_REVIEW_REQUEST',
    CREATE_ASSESSMENT_SUBMISSION_REVIEW_SUCCESS: 'CREATE_ASSESSMENT_SUBMISSION_REVIEW_SUCCESS',
    CREATE_ASSESSMENT_SUBMISSION_REVIEW_FAILURE: 'CREATE_ASSESSMENT_SUBMISSION_REVIEW_FAILURE',
}