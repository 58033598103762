import { userConstants } from '../constants';

let user = JSON.parse(localStorage.getItem('ipuser'));
let js_user = JSON.parse(localStorage.getItem('ipjsuser'));
const initialState = { loggedIn: !!user, js_loggedIn: !!js_user, user:null, js_user:null, users: [] } 

export const authentication = (state = initialState, action) => {
    switch (action.type) {
    case userConstants.USERS_SIGNIN_REQUEST:
    case userConstants.USERS_SIGNUP_REQUEST:
        return {
        loggingIn: true,
        user: action.user
        };
    case userConstants.USERS_SIGNIN_SUCCESS:
    case userConstants.USERS_SIGNUP_SUCCESS:
    case userConstants.VERIFY_AUTH_TOKEN_SUCCESS:
    case userConstants.VERIFY_EMAIL_SUCCESS:
    case userConstants.SET_PASSWORD_SUCCESS:
        return {
        loggedIn: true,
        user: action.user
        };
    case userConstants.VERIFY_JS_AUTH_TOKEN_SUCCESS:
    case userConstants.JOBSEEKER_SIGNIN_SUCCESS:
    case userConstants.UPDATE_JS_USER_SUCCESS:
        return Object.assign({}, state, {
            js_loggedIn:true,
            js_user: action.user
        })
    case userConstants.FETCH_USERS_SUCCESS:
        return Object.assign({}, state, {
            users: action.users,
        })
    case userConstants.ADD_USER_SUCCESS:
        return Object.assign({}, state, {
            users: state.users.concat(action.user),
        })
    case userConstants.USERS_SIGNIN_FAILURE:
    case userConstants.USERS_SIGNUP_FAILURE:
        return {};
    default:
        return state
    }
}