import { scoreConstants } from '../constants';

// let user = JSON.parse(localStorage.getItem('user'));
const initialState = {scores:[]};

export const scoreReducer = (state = initialState, action) => {
    switch (action.type) {
    case scoreConstants.CREATE_APPLICATION_SCORE_SUCCESS:
    case scoreConstants.CREATE_SCORE_SUCCESS:
        let scoreAlreadyExists = state.scores.find(item=>item.id===action.score.id)
        if(scoreAlreadyExists){
            return Object.assign({}, state, {
                scores: state.scores.map(score=>{
                    if(score.id===action.score.id){
                        return action.score
                    }
                    return score
                })
            });
        }
        return Object.assign({}, state, {
            scores: state.scores.concat(action.score)
        });
    case scoreConstants.FETCH_APPLICATION_SCORES_SUCCESS:
    case scoreConstants.FETCH_SCORES_SUCCESS:
        return Object.assign({}, state, {
            scores: action.scores
        });
    default:
        return state
    }
}