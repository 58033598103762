import { openingConstants } from '../constants';

// let user = JSON.parse(localStorage.getItem('user'));
const initialState = {is_fetching:false, applications: null, application:null, filters:[], job_opening: null, job_openings:[], job_functions:[],data:null, list_data:null, ps_questions:[]};

export const job_opening = (state = initialState, action) => {
    switch (action.type) {
    case openingConstants.UPDATE_JOBAPPLICATION_SUCCESS:
        return Object.assign({}, state, {
            application: action.job_application
        })
    case openingConstants.UPDATE_JS_JOBAPPLICATION_SUCCESS:
    case openingConstants.CREATE_JOBAPPLICATION_SUCCESS:
        return Object.assign({}, state, {
            job_application: action.job_application
        })
    case openingConstants.FETCH_PRESCREENING_QUESTIONS_SUCCESS:
        return Object.assign({}, state, {
            ps_questions: action.questions
        })
    case openingConstants.CREATE_RESPONSE_SUCCESS:
        return Object.assign({}, state, {
            ps_questions: state.ps_questions.map(question=>{
                if(question.id === action.response.interaction){
                    return Object.assign({}, {...question}, {'response': action.response, 'status': 'complete'})
                }
                return question
            })
        })
    case openingConstants.CREATE_VIDEO_RESPONSE_SUCCESS:
            return Object.assign({}, state, {
                ps_questions: state.ps_questions.map(question=>{
                    if(question.id === action.response.interaction){
                        return Object.assign({}, {...question}, {'response': action.response})
                    }
                    return question
                })
            })
    case openingConstants.CREATE_VIDEO_RESPONSE_PROGRESS:
            return Object.assign({}, state, {
                ps_questions: state.ps_questions.map(question=>{
                    if(question.id === action.question_id){
                        return Object.assign({}, {...question}, {'upload_progress': action.progress})
                    }
                    return question
                })
            })
    case openingConstants.FETCH_JOBOPENING_REQUEST:
    case openingConstants.FETCH_JOBCANDIDATES_REQUEST:
    case openingConstants.FETCH_JOBCANDIDATES_LIST_REQUEST:
    case openingConstants.FETCH_JOBCANDIDATE_REQUEST:
    case openingConstants.FETCH_JOBOPENINGS_REQUEST:
    case openingConstants.FETCH_JOBOPENING_FILTERS_REQUEST:
    case openingConstants.CREATE_JOBOPENING_REQUEST:
    case openingConstants.CREATE_JOBOPENING_QUESTION_REQUEST:
    case openingConstants.UPDATE_JOB_DESCRIPTION_REQUEST:
    case openingConstants.FETCH_JOBFUNCTIONS_REQUEST:
        return Object.assign({}, state, {
            is_fetching: true,
        })
    case openingConstants.ADD_JOBOPENING_REVIEWERS_SUCCESS:
        return Object.assign({}, state, {
            is_fetching: false,
            job_opening: Object.assign({}, state.job_opening, {
                reviewers: state.job_opening.reviewers.concat(action.reviewer.user) 
            }),
        })
    case openingConstants.CLEAR_JOBOPENING_SUCCESS:
        return Object.assign({}, state, {
            job_opening: null
        })
    case openingConstants.FETCH_JOBOPENING_SUCCESS:
    case openingConstants.FETCH_JOBOPENINGS_BY_ID_SUCCESS:
    case openingConstants.CREATE_JOBOPENING_SUCCESS:
    case openingConstants.UPDATE_JOBOPENING_SUCCESS:
    case openingConstants.UPDATE_JOBOPENING_STATUS_SUCCESS:
        let openingExist = state.job_openings.length && state.job_openings.find((opening)=> opening.id === action.job_opening.id)
        return Object.assign({}, state, {
            is_fetching: false,
            job_opening: action.job_opening,
            job_openings: openingExist ? state.job_openings.map((opening) =>{
                if(opening.id === action.job_opening.id){
                    return Object.assign({}, action.job_opening)
                }
                return opening;
            }) :
                state.job_openings.concat(action.job_opening)
        });

    case openingConstants.CREATE_JOBOPENING_QUESTION_SUCCESS:
        let {job_opening_question} = action
        let question = job_opening_question.interaction
        question.job_opening_question_id = job_opening_question.id
        question.order_no = job_opening_question.order_no

        return Object.assign({}, state, {
            is_fetching: false,
            job_opening: Object.assign({}, state.job_opening, {
                questions: state.job_opening.questions.concat(question) 
            }),
        })
    case openingConstants.ORDER_QUESTION_SUCCESS:
        return Object.assign({}, state, {
            is_fetching: false,
            job_opening: Object.assign({}, action.job_opening, {
                questions: action.job_opening.questions
            }),
        })
    case openingConstants.MARK_QUESTION_EDIT_SUCCESS:
        return Object.assign({}, state, {
            job_opening: Object.assign({}, state.job_opening, {
                questions: state.job_opening.questions.map((question)=>{
                    if(question.job_opening_question_id === action.job_opening_question.job_opening_question_id){
                        return Object.assign({}, {...question}, {'is_editing': true})
                    }
                    return question
                })
            })
        })
    case openingConstants.UNMARK_QUESTION_EDIT_SUCCESS:
        return Object.assign({}, state, {
            job_opening: Object.assign({}, state.job_opening, {
                questions: state.job_opening.questions.map((question)=>{
                    if(question.job_opening_question_id === action.job_opening_question_id){
                        return Object.assign({}, {...question}, {'is_editing': false})
                    }
                    return question
                })
            })
        })
    case openingConstants.UPDATE_QUESTION_SUCCESS:
        return Object.assign({}, state, {
            job_opening: Object.assign({}, state.job_opening, {
                questions: state.job_opening.questions.map((question)=>{
                    if(question.job_opening_question_id === action.job_opening_question.id){
                        let {job_opening_question} = action;
                        let question = job_opening_question.interaction;
                        question.job_opening_question_id = job_opening_question.id
                        question.order_no = job_opening_question.order_no
                        question.is_editing = false
                        return Object.assign({}, question)
                    }
                    return question
                })
            })
        })
    case openingConstants.order_QUESTION_SUCCESS:
        return Object.assign({}, state, {
            job_opening: Object.assign({}, state.job_opening, {
                questions: state.job_opening.questions.map((question)=>{
                    if(question.job_opening_question_id === action.job_opening_question.id){
                        let {job_opening_question} = action;
                        let question = job_opening_question.interaction;
                        question.job_opening_question_id = job_opening_question.id
                        question.order_no = job_opening_question.order_no
                        question.is_editing = false
                        return Object.assign({}, question)
                    }
                    return question
                })
            })
        })
    case openingConstants.DELETE_QUESTION_SUCCESS:        
        return Object.assign({}, state, {
            is_fetching: false,
            job_opening: Object.assign({}, action.job_opening, {
                questions: action.job_opening.questions
            }),
        })

    case openingConstants.UPDATE_QUESTION_STATE_SUCCESS_OLD:
        return Object.assign({}, state, {
            job_opening: Object.assign({}, state.job_opening, {
                questions: state.job_opening.questions.map((question)=>{
                    let {job_opening_question} = action;
                    if(question.job_opening_question_id === job_opening_question.job_opening_question_id){
                        let {question_title, question_type} = job_opening_question;
                        question.updated_question_title = question_title
                        question.updated_question_type = question_type
                        return Object.assign({}, question)
                    }
                    return question
                })
            })
        })
    case openingConstants.UPDATE_JOB_DESCRIPTION_SUCCESS:
        return Object.assign({}, state, {
            job_opening: Object.assign({}, action.job_opening),
            job_openings: state.job_openings.map(opening =>{
                if(opening.id === action.opening_id){
                    return Object.assign({}, action.job_opening);
                }
                return opening;
            })
        })

    case openingConstants.FETCH_JOBOPENINGS_SUCCESS:
        return Object.assign({}, state, {
            is_fetching: false,
            job_openings: action.job_openings
        });
    
    case openingConstants.FETCH_JOBOPENING_FILTERS_SUCCESS:
        return Object.assign({}, state, {
            is_fetching: false,
            filters: action.filters,
        });

    case openingConstants.UPDATE_FILTER:
        let filterExist = state.filters.length && state.filters.find((filter)=> filter.filter_id === action.filter.filter_id)
        return Object.assign({}, state, {
            
            filters: filterExist ? state.filters.map((filter) =>{
                if(filter.filter_id === action.filter.filter_id){
                    return Object.assign({}, {...action.filter, 'selected_value': action.filter.value})
                }
                return filter;
            }) :
                state.filters.concat({...action.filter, 'selected_value': action.filter.value})
        });

    case openingConstants.FETCH_JOBFUNCTIONS_SUCCESS:
        return Object.assign({}, state, {
            is_fetching: false,
            job_functions: action.job_functions
        })
    
    case openingConstants.FETCH_JOBCANDIDATES_SUCCESS:
        return Object.assign({}, state, {
            is_fetching: false,
            applications: action.applications
        })
    
    case openingConstants.FETCH_MORE_JOBCANDIDATES_SUCCESS:
        return Object.assign({}, state, {
            is_fetching: false,
            applications: Object.assign({}, state.applications, {
                results: state.applications.results.concat(action.applications.results),
                next: action.applications.next
            })
        })
    
    case openingConstants.FETCH_JOBCANDIDATES_LIST_SUCCESS:
        return Object.assign({}, state, {
            is_fetching: false,
            list_data: action.data
        })
    
    case openingConstants.FETCH_JOBCANDIDATE_SUCCESS:
        return Object.assign({}, state, {
            is_fetching: false,
            application: action.application && action.application.results.length && action.application.results[0]
        })
    
    case openingConstants.CLEAR_APPLICATION:
        return Object.assign({}, state, {
            is_fetching: false,
            application: null
        })

    case openingConstants.FETCH_JOBOPENING_FAILURE:
    case openingConstants.FETCH_JOBOPENINGS_FAILURE:
    case openingConstants.FETCH_JOBOPENING_FILTERS_FAILURE:
    case openingConstants.FETCH_JOBCANDIDATES_FAILURE:
    case openingConstants.FETCH_MORE_JOBCANDIDATES_FAILURE:
    case openingConstants.FETCH_JOBCANDIDATES_LIST_FAILURE:
    case openingConstants.FETCH_JOBCANDIDATE_FAILURE:
    case openingConstants.CREATE_JOBOPENING_FAILURE:
    case openingConstants.CREATE_JOBOPENING_QUESTION_FAILURE:
    case openingConstants.UPDATE_JOB_DESCRIPTION_FAILURE:
    case openingConstants.FETCH_JOBFUNCTIONS_FAILURE:
        return Object.assign({}, state, {
            is_fetching: false,
            error: action.error
        })
    default:
        return state
    }
}