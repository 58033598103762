import {connect} from 'react-redux';
import NavBar from './NavBar'
import {userActions} from '../../actions/userActions';


const mapStateToProps = (state) => ({
    current_user: state.authentication && state.authentication.user,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    signOut: () => {
        return dispatch(userActions.signOut())
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);