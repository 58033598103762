import {starService} from '../services';
import {alertActions, userActions} from '../actions';
import { starConstants } from '../constants';
import {analytics} from '../helpers';

const createStar = (response_id) => {
    return dispatch => {
        dispatch(request());
        starService.createStar(response_id)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                analytics.track('star_create', {response_id})
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: starConstants.CREATE_STAR_REQUEST} }
        function success(star) { return { type: starConstants.CREATE_STAR_SUCCESS, star } }
        function failure(error) { return { type: starConstants.CREATE_STAR_FAILURE, error } }
    }
}

const fetchStars = (response_id) => {
    return dispatch => {
        dispatch(request());
        starService.fetchStars(response_id)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: starConstants.FETCH_STARS_REQUEST} }
        function success(stars) { return { type: starConstants.FETCH_STARS_SUCCESS, stars } }
        function failure(error) { return { type: starConstants.FETCH_STARS_FAILURE, error } }
    }
}

const createStarForApplication = (job_application_uid) => {
    return dispatch => {
        dispatch(request());
        starService.createStarForApplication(job_application_uid)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                analytics.track('star_create', {job_application_uid})
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: starConstants.CREATE_APPLICATION_STAR_REQUEST} }
        function success(star) { return { type: starConstants.CREATE_APPLICATION_STAR_SUCCESS, star } }
        function failure(error) { return { type: starConstants.CREATE_APPLICATION_STAR_FAILURE, error } }
    }
}

const fetchStarsForApplication = (job_application_uid) => {
    return dispatch => {
        dispatch(request());
        starService.fetchStarsForApplication(job_application_uid)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: starConstants.FETCH_APPLICATION_STARS_REQUEST} }
        function success(stars) { return { type: starConstants.FETCH_APPLICATION_STARS_SUCCESS, stars } }
        function failure(error) { return { type: starConstants.FETCH_APPLICATION_STARS_FAILURE, error } }
    }
}
export const starActions = {
    createStar,
    fetchStars,
    fetchStarsForApplication,
    createStarForApplication,
}