import { alertActions } from "./alertActions";
import { userConstants } from '../constants';
import { userService } from '../services';
import {history, analytics} from '../helpers';
import {js_analytics} from '../helpers/analytics';
import { openingActions } from "./openingActions";
// import {assessmentActions} from "./assessmentActions";


const signIn = (email, password) => {
    return dispatch => {
        dispatch(request());
        userService.signIn(email, password)
            .then(user=>{
                dispatch(success(user.user));
                dispatch(verifyAuthToken());
                analytics.identify(user.user.id);
                analytics.track('signin_successful');
                console.log(user)
                analytics.people.set({
                    $first_name: user.user.first_name,
                    $last_name: user.user.last_name,
                    $email: user.user.email,
                    $user_id: user.user.id,
                });
                history.push('/interviews/');
            })
            .catch(error=>{
                analytics.track('signin_failure');
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
            })
    }

    function request() { return { type: userConstants.USERS_SIGNIN_REQUEST } }
    function success(user) { return { type: userConstants.USERS_SIGNIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.USERS_SIGNIN_FAILURE, error } }
}

const signUpAnonymousUser = (job_opening_id) => {
    return dispatch => {
        dispatch(request());
        userService.SignUpAnonymousUser()
            .then(user=>{
                dispatch(success(user.user));
                dispatch(openingActions.createJobApplication(job_opening_id))
            })
            .catch(error=>{
                analytics.track('anon_signup_failure');
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
            })
    }
    function request() { return { type: userConstants.ANON_USERS_SIGNIN_REQUEST } }
    function success(user) { return { type: userConstants.ANON_USERS_SIGNIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.ANON_USERS_SIGNIN_FAILURE, error } }
}

const signInJobSeeker = (job_opening_id, user) => {
    return dispatch => {
        dispatch(request());
        userService.signInJobSeeker(user)
            .then(user=>{
                js_analytics.identify(user.user.id);
                js_analytics.people.set({
                    $first_name: user.user.first_name,
                    $last_name: user.user.last_name,
                    $email: user.user.email,
                    $user_id: user.user.id,
                });
                js_analytics.track('js_signin_success');
                dispatch(success(user.user));
                dispatch(openingActions.createJobApplication(job_opening_id))
            })
            .catch(error=>{
                js_analytics.track('js_signin_failure');
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
            })
    }
    function request() { return { type: userConstants.JOBSEEKER_SIGNIN_REQUEST } }
    function success(user) { return { type: userConstants.JOBSEEKER_SIGNIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.JOBSEEKER_SIGNIN_FAILURE, error } }
}

const signInJobSeekerAlone = (user, assessment_uid, callback) => {
    return dispatch => {
        dispatch(request());
        userService.signInJobSeeker(user)
            .then(user=>{
                js_analytics.identify(user.user.id);
                js_analytics.people.set({
                    $first_name: user.user.first_name,
                    $last_name: user.user.last_name,
                    $email: user.user.email,
                    $user_id: user.user.id,
                });
                js_analytics.track('js_signin_success');
                dispatch(success(user.user));
                callback && callback(assessment_uid);
            })
            .catch(error=>{
                js_analytics.track('js_signin_failure');
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
            })
    }
    function request() { return { type: userConstants.JOBSEEKER_SIGNIN_REQUEST } }
    function success(user) { return { type: userConstants.JOBSEEKER_SIGNIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.JOBSEEKER_SIGNIN_FAILURE, error } }
}


const signOut = () => {
    return dispatch => {
        userService.signOut();
        history.push('/');
        window.Intercom && window.Intercom('shutdown')
        analytics.track('signout_successful');
        dispatch(success());
        function success() {return {type:userConstants.USERS_SIGNOUT} }
    }
}

const signJSOut = () => {
    return dispatch => {
        userService.signJSOut();
        // history.push('/');
        window.Intercom && window.Intercom('shutdown')
        dispatch(success());
        function success() {return {type:userConstants.USERS_JS_SIGNOUT} }
    }
}


const signUp = (first_name, last_name, email, password1, password2, phone, jumpToStep) => {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.clear());
        userService.signUp(first_name, last_name, email, password1, password2, phone)
            .then(user=>{
                dispatch(success(user));
                console.log(user)
                dispatch(verifyAuthToken());
                analytics.identify(user.user.id || user.user.pk);
                analytics.track('signup_successful');
                analytics.people.set({
                    $first_name: user.user.first_name,
                    $last_name: user.user.last_name,
                    $email: user.user.email,
                });
                jumpToStep(1);
                // history.push('/');
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                return false
            })
    }

    function request() { return { type: userConstants.USERS_SIGNUP_REQUEST } }
    function success(user) { return { type: userConstants.USERS_SIGNUP_SUCCESS, user } }
    function failure(error) { return { type: userConstants.USERS_SIGNUP_FAILURE, error } }
}


const addUser = (first_name, last_name, email, invited=false, role='hire-reviewers') => {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.clear());
        userService.addUser(first_name, last_name, email, invited, role)
            .then(user=>{
                dispatch(alertActions.success("User added successfully. Invite is sent to their email.", "ADD_USER"));
                dispatch(success(user.user));
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                return false
            })
    }

    function request() { return { type: userConstants.ADD_USER_REQUEST } }
    function success(user) { return { type: userConstants.ADD_USER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.ADD_USER_FAILURE, error } }
}

const updateJSUser = (user_id, first_name, last_name, email, phone, roll_no, department, college) => {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.clear());
        userService.updateJSUser(user_id, first_name, last_name, email, phone, roll_no, department, college)
            .then(user=>{
                dispatch(success(user.user));
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                return false
            })
    }

    function request() { return { type: userConstants.UPDATE_JS_USER_REQUEST } }
    function success(user) { return { type: userConstants.UPDATE_JS_USER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_JS_USER_FAILURE, error } }
}


const fetchJobSeeker = (profile_id) => {
    return dispatch => {
        dispatch(request());
        userService.fetchJobSeeker(profile_id)
            .then(data=>{
                dispatch(success(data))
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(signOut());
                }
            })
        function request() { return { type: userConstants.FETCH_JOBSEEKER_REQUEST } }
        function success(job_seeker) { return { type: userConstants.FETCH_JOBSEEKER_SUCCESS, job_seeker } }
        function failure(error) { return { type: userConstants.FETCH_JOBSEEKER_FAILURE, error } }
    }
}

const fetchUsers = () => {
    return dispatch => {
        dispatch(request());
        userService.fetchUsers()
            .then(data=>{
                dispatch(success(data))
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(signOut());
                }
            })
        function request() { return { type: userConstants.FETCH_USERS_REQUEST } }
        function success(users) { return { type: userConstants.FETCH_USERS_SUCCESS, users } }
        function failure(error) { return { type: userConstants.FETCH_USERS_FAILURE, error } }
    }
}

const verifyAuthToken = () =>{
    return dispatch => {
        dispatch(request());
        let userToken = JSON.parse(localStorage.getItem('ipuser'));
        userService.verifyAuthToken(userToken)
            .then(data=>{
                dispatch(success(data.user))
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(signOut());
                }
            })
        function request() { return { type: userConstants.VERIFY_AUTH_TOKEN_REQUEST } }
        function success(user) { return { type: userConstants.VERIFY_AUTH_TOKEN_SUCCESS, user } }
        function failure(error) { return { type: userConstants.VERIFY_AUTH_TOKEN_FAILURE, error } }
    }
}

const verifyJSAuthToken = () =>{
    return dispatch => {
        dispatch(request());
        let userToken = JSON.parse(localStorage.getItem('ipjsuser'));
        userService.verifyAuthToken(userToken)
            .then(data=>{
                dispatch(success(data.user))
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if([400, 401, 402].indexOf(error.response.status) !== -1){
                    dispatch(signJSOut());
                }
            })
        function request() { return { type: userConstants.VERIFY_JS_AUTH_TOKEN_REQUEST } }
        function success(user) { return { type: userConstants.VERIFY_JS_AUTH_TOKEN_SUCCESS, user } }
        function failure(error) { return { type: userConstants.VERIFY_JS_AUTH_TOKEN_FAILURE, error } }
    }
}

const verifyEmail = (key) => {
    return dispatch => {
        dispatch(request());
        userService.verifyEmail(key)
            .then(data=>{
                dispatch(success(data.user))
                dispatch(alertActions.success('User verified successfully'))
                history.push('/interviews/');
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data.error));
                if(error.response.status === 401){
                    dispatch(signOut());
                }
            })
        function request() { return { type: userConstants.VERIFY_EMAIL_REQUEST } }
        function success(user) { return { type: userConstants.VERIFY_EMAIL_SUCCESS, user } }
        function failure(error) { return { type: userConstants.VERIFY_EMAIL_FAILURE, error } }
    }
}

const setPassword = (key, first_name, last_name, password) => {
    return dispatch => {
        dispatch(request());
        userService.setPassword(key, first_name, last_name, password)
            .then(data=>{
                dispatch(success(data.user))
                dispatch(alertActions.success('User verified successfully'))
                history.push('/onwards/');
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data.error));
                if(error.response.status === 401){
                    dispatch(signOut());
                }
            })
        function request() { return { type: userConstants.SET_PASSWORD_REQUEST } }
        function success(user) { return { type: userConstants.SET_PASSWORD_SUCCESS, user } }
        function failure(error) { return { type: userConstants.SET_PASSWORD_FAILURE, error } }
    }
}

const resetPassword = (email) => {
    return dispatch => {
        dispatch(request());
        userService.resetPassword(email)
            .then(data=>{
                dispatch(success(data.user))
                dispatch(alertActions.success('Password reset email has been sent.'))
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data.error));
                if(error.response.status === 401){
                    dispatch(signOut());
                }
            })
        function request() { return { type: userConstants.RESET_PASSWORD_REQUEST } }
        function success(user) { return { type: userConstants.RESET_PASSWORD_SUCCESS, user } }
        function failure(error) { return { type: userConstants.RESET_PASSWORD_FAILURE, error } }
    }
}

const resetPasswordConfirm = (uid, toke, new_password1, new_password2) => {
    return dispatch => {
        dispatch(request());
        userService.resetPasswordConfirm(uid, toke, new_password1, new_password2)
            .then(data=>{
                dispatch(success(data.user))
                dispatch(alertActions.success('Password has been reset with the new password.'))
            })
            .catch(error=>{
                console.log(error);
                console.log(error.response);
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(signOut());
                }
            })
        function request() { return { type: userConstants.RESET_PASSWORD_CONFIRM_REQUEST } }
        function success(user) { return { type: userConstants.RESET_PASSWORD_CONFIRM_SUCCESS, user } }
        function failure(error) { return { type: userConstants.RESET_PASSWORD_CONFIRM_FAILURE, error } }
    }
}


export const userActions = {
    signInJobSeeker,
    signInJobSeekerAlone,
    signUpAnonymousUser,
    signIn,
    signOut,
    signJSOut,
    signUp,
    fetchJobSeeker,
    verifyAuthToken,
    verifyJSAuthToken,
    verifyEmail,
    fetchUsers,
    addUser,
    updateJSUser,
    setPassword,
    resetPassword,
    resetPasswordConfirm,
}