import { reviewConstants } from '../constants/reviewConstants';

// let user = JSON.parse(localStorage.getItem('user'));
const initialState = {reviews:[]};

export const reviewReducer = (state = initialState, action) => {
    switch (action.type) {
    case reviewConstants.CREATE_ASSESSMENT_SUBMISSION_REVIEW_SUCCESS:
    case reviewConstants.CREATE_APPLICATION_REVIEW_SUCCESS:
    case reviewConstants.CREATE_REVIEW_SUCCESS:
        return Object.assign({}, state, {
            reviews: state.reviews.concat(action.review)
        });
    case reviewConstants.FETCH_ASSESSMENT_SUBMISSION_REVIEWS_SUCCESS:
    case reviewConstants.FETCH_APPLICATION_REVIEWS_SUCCESS:
    case reviewConstants.FETCH_REVIEWS_SUCCESS:
        return Object.assign({}, state, {
            reviews: action.reviews
        });
    default:
        return state
    }
}