import { assessmentConstants } from '../constants';

const initialState = {assessments:[], templates:[], assessment:{questions:[]}, assessment_submissions:[], assessment_submission:{questions:[]}, assessment_candidate:{results:[]}, assessment_candidates:{results:[]}};

export const assessmentReducer = (state = initialState, action) => {
    switch (action.type) {
    case assessmentConstants.CREATE_ASSESSMENTS_SUCCESS:
        return Object.assign({}, state, {
            assessments: state.assessments ? state.assessments.concat(action.assessments) : [action.assessments]
        });
    case assessmentConstants.FETCH_ASSESSMENTS_SUCCESS:
        return Object.assign({}, state, {
            assessments: action.assessments
        });
    case assessmentConstants.FETCH_ASSESSMENT_SUCCESS:
        return Object.assign({}, state, {
            assessment: action.assessment
        });
    case assessmentConstants.FETCH_ASSESSMENT_TEMPLATES_SUCCESS:
        return Object.assign({}, state, {
            templates: action.assessments
        });
    case assessmentConstants.UPDATE_ASSESSMENT_SUBMISSION_SUCCESS:
    case assessmentConstants.CREATE_ASSESSMENT_SUBMISSION_SUCCESS:
        return Object.assign({}, state, {
            assessment_submissions: state.assessment_submissions ? state.assessment_submissions.map(submission=>{
                return submission.assessment.uid===action.assessment_submission.assessment.uid ? action.assessment_submission :submission;
            }) : [action.assessment_submission],
            assessment_submission: action.assessment_submission
        });
    case assessmentConstants.FETCH_ASSESSMENT_SUBMISSIONS_SUCCESS:
        return Object.assign({}, state, {
            assessment_submissions: action.assessment_submissions
        });
    case assessmentConstants.FETCH_ASSESSMENT_CANDIDATES_SUCCESS:
        return Object.assign({}, state, {
            assessment_candidates: action.assessment_candidates
        });
    case assessmentConstants.CLEAR_ASSESSMENT_CANDIDATE_SUCCESS:
    case assessmentConstants.FETCH_ASSESSMENT_CANDIDATE_SUCCESS:
        return Object.assign({}, state, {
            assessment_candidate: action.assessment_candidate
        });
    case assessmentConstants.FETCH_MORE_ASSESSMENT_CANDIDATES_SUCCESS:
        return Object.assign({}, state, {
            assessment_candidates: Object.assign({}, state.assessment_candidates, {
                results: state.assessment_candidates.results.concat(action.assessment_candidates.results),
                next: action.assessment_candidates.next
            })
        });
    case assessmentConstants.ORDER_ASSESSMENT_QUESTION_SUCCESS:
    case assessmentConstants.DELETE_ASSESSMENT_QUESTION_SUCCESS:
        return Object.assign({}, state, {
            assessment: Object.assign({}, state.assessment, {
                questions: action.questions
            })
        });
    case assessmentConstants.UPDATE_ASSESSMENT_QUESTION_SUCCESS:
        return Object.assign({}, state, {
            assessment: Object.assign({}, state.assessment, {
                questions: state.assessment.questions.map((question)=>{
                    if(question.assessment_question_id === action.assessment_question.id){
                        let {assessment_question} = action;
                        let question = assessment_question.interaction;
                        question.assessment_question_id = assessment_question.id
                        question.order_no = assessment_question.order_no
                        question.is_editing = false
                        return Object.assign({}, question)
                    }
                    return question
                })
            })
        });
    case assessmentConstants.CREATE_ASSESSMENT_RESPONSE_SUCCESS:
        return Object.assign({}, state, {
                assessment_submission: Object.assign({}, state.assessment_submission, {
                    questions: state.assessment_submission.questions.map(question=>{
                        if(question.id === action.response.interaction){
                            return Object.assign({}, {...question}, {'response': action.response, 'status':'complete'})
                        }
                        return question
                }),
            })
        });
    case assessmentConstants.CREATE_ASSESSMENT_VIDEO_RESPONSE_SUCCESS:
        return Object.assign({}, state, {
                assessment_submission: Object.assign({}, state.assessment_submission, {
                    questions: state.assessment_submission.questions.map(question=>{
                        if(question.id === action.response.interaction){
                            return Object.assign({}, {...question}, {'response': action.response})
                        }
                        return question
                }),
            })
        });
    case assessmentConstants.CREATE_ASSESSMENT_VIDEO_RESPONSE_PROGRESS:
        return Object.assign({}, state, {
                assessment_submission: Object.assign({}, state.assessment_submission, {
                    questions: state.assessment_submission.questions.map(question=>{
                        if(question.id === action.question_id){
                            return Object.assign({}, {...question}, {'upload_progress': action.progress})
                        }
                        return question
                }),
            })
        });
    case assessmentConstants.CREATE_ASSESSMENT_QUESTION_SUCCESS:
        let {assessment_question} = action
        let question = assessment_question.interaction
        question.assessment_question_id = assessment_question.id
        question.order_no = assessment_question.order_no

        return Object.assign({}, state, {
            is_fetching: false,
            assessment: Object.assign({}, state.assessment, {
                questions: state.assessment.questions.concat(question)
            }),
        });
    case assessmentConstants.ADD_ASSESSMENT_REVIEWERS_SUCCESS:
        return Object.assign({}, state, {
            assessment: Object.assign({}, state.assessment, {
                reviewers: state.assessment.reviewers.concat(action.reviewer.user)
            }),
        })
    default:
        return state
    }
}
