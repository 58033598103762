import {noteService} from '../services';
import {alertActions, userActions} from '../actions';
import { noteConstants } from '../constants';
import {analytics} from '../helpers';

const createNote = (text, response_id) => {
    return dispatch => {
        dispatch(request());
        noteService.createNote(text, response_id)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                analytics.track('note_create', {response_id})
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: noteConstants.CREATE_NOTE_REQUEST} }
        function success(note) { return { type: noteConstants.CREATE_NOTE_SUCCESS, note } }
        function failure(error) { return { type: noteConstants.CREATE_NOTE_FAILURE, error } }
    }
}

const setSelectedResponse = (response_id) =>{
    return dispatch => {
        dispatch(success({response_id}))
    }
    function success(data) { return { type: noteConstants.SET_SELECTED_RESPONSE, data } }
}

const fetchNotes = (response_id) => {
    return dispatch => {
        dispatch(request());
        noteService.fetchNotes(response_id)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: noteConstants.FETCH_NOTES_REQUEST} }
        function success(notes) { return { type: noteConstants.FETCH_NOTES_SUCCESS, notes } }
        function failure(error) { return { type: noteConstants.FETCH_NOTES_FAILURE, error } }
    }
}

const createNoteForApplication = (text, job_application_uid) => {
    return dispatch => {
        dispatch(request());
        noteService.createNoteForApplication(text, job_application_uid)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                analytics.track('note_create', {job_application_uid})
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: noteConstants.CREATE_APPLICATION_NOTE_REQUEST} }
        function success(note) { return { type: noteConstants.CREATE_APPLICATION_NOTE_SUCCESS, note } }
        function failure(error) { return { type: noteConstants.CREATE_APPLICATION_NOTE_FAILURE, error } }
    }
}

const fetchNotesForApplication = (job_application_uid) => {
    return dispatch => {
        dispatch(request());
        noteService.fetchNotesForApplication(job_application_uid)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: noteConstants.FETCH_APPLICATION_NOTES_REQUEST} }
        function success(notes) { return { type: noteConstants.FETCH_APPLICATION_NOTES_SUCCESS, notes } }
        function failure(error) { return { type: noteConstants.FETCH_APPLICATION_NOTES_FAILURE, error } }
    }
}

const createNoteForAssessmentSubmission = (text, assessment_submission_uid) => {
    return dispatch => {
        dispatch(request());
        noteService.createNoteForAssessmentSubmission(text, assessment_submission_uid)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                analytics.track('note_create', {assessment_submission_uid})
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: noteConstants.CREATE_ASSESSMENT_SUBMISSION_NOTE_REQUEST} }
        function success(note) { return { type: noteConstants.CREATE_ASSESSMENT_SUBMISSION_NOTE_SUCCESS, note } }
        function failure(error) { return { type: noteConstants.CREATE_ASSESSMENT_SUBMISSION_NOTE_FAILURE, error } }
    }
}

const fetchNotesForAssessmentSubmission = (assessment_submission_uid) => {
    return dispatch => {
        dispatch(request());
        noteService.fetchNotesForAssessmentSubmission(assessment_submission_uid)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: noteConstants.FETCH_ASSESSMENT_SUBMISSION_NOTES_REQUEST} }
        function success(notes) { return { type: noteConstants.FETCH_ASSESSMENT_SUBMISSION_NOTES_SUCCESS, notes } }
        function failure(error) { return { type: noteConstants.FETCH_ASSESSMENT_SUBMISSION_NOTES_FAILURE, error } }
    }
}

export const noteActions = {
    createNote,
    fetchNotes,
    setSelectedResponse,
    fetchNotesForApplication,
    createNoteForApplication,
    fetchNotesForAssessmentSubmission,
    createNoteForAssessmentSubmission,
}