import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import Loadable from 'react-loadable';
import { history, analytics } from './helpers';
import { alertActions } from './actions';
import { PrivateRoute } from './components/PrivateRoute';
import ErrorBoundary from './components/ErrorBoundary';
import Loading from './components/Loading';
// import SignInPage from './pages/SignInPage';
// import SignUpPage from './pages/SignUpPage';
// import HomePage from './pages/HomePage';
// import ProfilePage from './pages/ProfilePage';
// import ProfileVideosPage from './pages/ProfileVideosPage';
// import JobRedirectPage from './pages/JobRedirectPage';
// import JobDetailPage from './pages/JobDetailPage';
// import JobsPage from './pages/JobsPage';
// import JobsCreatePage from './pages/JobsCreatePage';
// import JobCandidatesPage from './pages/JobCandidatesPage';
// import JobCandidatePage from './pages/JobCandidatePage';
// import JobCandidateVideosPage from './pages/JobCandidateVideosPage';
// import JobCandidatesListPage from './pages/JobCandidatesListPage';
// import SettingsPage from './pages/SettingsPage';
// import EmailVerificationPage from './pages/EmailVerificationPage';
// import InviteVerificationPage from './pages/InviteVerificationPage';
// import OnboardingPage from './pages/OnboardingPage';
// import PreScreeningPage from './pages/PreScreeningPage';
import {isLoggedIn, isJSLoggedIn} from './helpers/authHeader'
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

let LoadablePreScreening = Loadable({
  loader: () => import('./pages/PreScreeningPage'),
  loading: Loading
});
let LoadableInviteVerificationPage = Loadable({
  loader: () => import('./pages/InviteVerificationPage'),
  loading: Loading
});
let LoadableEmailVerificationPage = Loadable({
  loader: () => import('./pages/EmailVerificationPage'),
  loading: Loading
});
let LoadableJobsPage = Loadable({
  loader: () => import('./pages/JobsPage'),
  loading: Loading
});
let LoadableAssessmentsPage = Loadable({
  loader: () => import('./pages/AssessmentsPage'),
  loading: Loading
});
let LoadableAssessmentsEditPage = Loadable({
  loader: () => import('./pages/AssessmentsEditPage'),
  loading: Loading
});
let LoadableSettingsPage = Loadable({
  loader: () => import('./pages/SettingsPage'),
  loading: Loading
});
let LoadableOnboardingPage = Loadable({
  loader: () => import('./pages/OnboardingPage'),
  loading: Loading
});
let LoadableJobsCreatePage = Loadable({
  loader: () => import('./pages/JobsCreatePage'),
  loading: Loading
});
let LoadableSignInPage = Loadable({
  loader: () => import('./pages/SignInPage'),
  loading: Loading
});
let LoadableSignUpPage = Loadable({
  loader: () => import('./pages/SignUpPage'),
  loading: Loading
});

let LoadableProfileVideosPage = Loadable({
  loader: () => import('./pages/ProfileVideosPage'),
  loading: Loading
});
let LoadableJobDetailPage = Loadable({
  loader: () => import('./pages/JobDetailPage'),
  loading: Loading
});
let LoadableJobRedirectPage = Loadable({
  loader: () => import('./pages/JobRedirectPage'),
  loading: Loading
});
let LoadableProfilePage = Loadable({
  loader: () => import('./pages/ProfilePage'),
  loading: Loading
});
let LoadableJobCandidatesPage = Loadable({
  loader: () => import('./pages/JobCandidatesPage'),
  loading: Loading
});
let LoadableJobCandidatePage = Loadable({
  loader: () => import('./pages/JobCandidatePage'),
  loading: Loading
});
let LoadableJobCandidateVideosPage = Loadable({
  loader: () => import('./pages/JobCandidateVideosPage'),
  loading: Loading
});
let LoadableJobCandidatesListPage = Loadable({
  loader: () => import('./pages/JobCandidatesListPage'),
  loading: Loading
});
let LoadableInterviewPage = Loadable({
  loader: () => import('./pages/InterviewPage'),
  loading: Loading
});
let LoadableGenericInterviewPage = Loadable({
  loader: () => import('./pages/GenericInterviewPage'),
  loading: Loading
});
let LoadableExpressInterviewPage = Loadable({
  loader: () => import('./pages/ExpressInterviewPage'),
  loading: Loading
});
let LoadableAssessmentSubmissionPage = Loadable({
  loader: () => import('./pages/AssessmentSubmissionPage'),
  loading: Loading
});
let LoadableAssessmentDetailPage = Loadable({
  loader: () => import('./pages/AssessmentDetailPage'),
  loading: Loading
})
let LoadablePasswordResetPage = Loadable({
  loader: () => import('./pages/PasswordResetPage'),
  loading: Loading
})
let LoadablePasswordResetConfirmPage = Loadable({
  loader: () => import('./pages/PasswordResetConfirmPage'),
  loading: Loading
})
let LoadableTestAudioVideoPage = Loadable({
  loader: () => import('./pages/TestAudioVideoPage'),
  loading: Loading
})
let LoadablePublicJobCandidateVideosPage = Loadable({
  loader: () => import('./pages/PublicJobCandidateVideosPage'),
  loading: Loading
})


export default class App extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;
    // console.log(localStorage.getItem('ipuser'))
    history.listen((location, action) => {
      analytics.track('page_change', {'path': location.pathname})
      dispatch(alertActions.clear());
    });
  }

  componentDidMount(){
    if(isLoggedIn() && this.props.user && !this.props.user.hasOwnProperty('username')){
      analytics.people.set({
        $first_name: this.props.user.first_name,
        $last_name: this.props.user.last_name,
        $email: this.props.user.email,
      });
    }
  }

  showHelp() {
      let {user, js_user} = this.props;
      if ((process.env.NODE_ENV === "production" && isLoggedIn() && user && window.Intercom)||(process.env.NODE_ENV === "production" && isJSLoggedIn() && js_user && window.Intercom)) {
        let loggedin_user = user || js_user;
        let name = loggedin_user.first_name ? loggedin_user.first_name : "" + loggedin_user.last_name ? " " + loggedin_user.last_name : ""
        window.Intercom('boot', {
          app_id: "hps1qe0c",
          name: name,
          email: loggedin_user.email,
          created_at: loggedin_user.created_at,
          company_name: loggedin_user.organization && loggedin_user.organization.name,
          dashboard_user: user ? true : false,
        })
      }else{
        window.Intercom('shutdown')
      }
  }

  render() {
    return (
      <div className="sub-root">
          {this.showHelp()}
          <I18nextProvider i18n={i18n}>
            <Router history={history}>
              <div className="sub-root">
                <ErrorBoundary>
                  <Route exact path="/test-center/assessment/:assessment_uid" component={LoadableAssessmentSubmissionPage} />
                  <Switch>
                    <Redirect from='/jd/:job_uid' to='/winterview/:job_uid'/>
                    <Route exact path="/winterview/:job_uid" component={LoadableInterviewPage} />
                  </Switch>
                  <Route exact path="/record/interview/:job_uid/" component={LoadableGenericInterviewPage} />
                  <Route exact path="/express-interview/:job_uid/:user_uid" component={LoadableExpressInterviewPage} />
                  <PrivateRoute exact path="/" component={LoadableJobsPage} />
                  <PrivateRoute exact path="/interview/create/" component={LoadableJobsCreatePage} />
                  <PrivateRoute exact path="/interview/:job_opening_uid/edit/:step" component={LoadableJobsCreatePage} />
                  <PrivateRoute exact path="/interviews/:job_uid/profile/:profile_uid/videos/:index" component={LoadableJobCandidateVideosPage} />
                  <PrivateRoute exact path="/interviews/:job_uid/profile/:profile_uid/" component={LoadableJobCandidatePage} />
                  <PrivateRoute exact path="/interviews/:job_uid/list" component={LoadableJobCandidatesListPage} />
                  <PrivateRoute exact path="/interviews/:job_uid" component={LoadableJobCandidatesPage} />
                  <PrivateRoute exact path="/interviews/" component={LoadableJobsPage} />
                  <PrivateRoute exact path="/assessments/:assessment_uid/edit" component={LoadableAssessmentsEditPage} />
                  <PrivateRoute exact path="/assessments/:assessment_uid" component={LoadableAssessmentDetailPage} />
                  <PrivateRoute exact path="/assessments/" component={LoadableAssessmentsPage} />
                  <PrivateRoute exact path="/settings/" component={LoadableSettingsPage} />
                  <PrivateRoute path="/onwards" component={LoadableOnboardingPage} />
                  <Route exact path="/verify/:key" component={LoadableEmailVerificationPage} />
                  <Route exact path="/invite/:key" component={LoadableInviteVerificationPage} />
                  <Route exact path="/profile/:profile_id/videos/:index" component={LoadableProfileVideosPage} />
                  <Route exact path="/profile/:profile_id/videos/" component={LoadableProfileVideosPage} />
                  <Route exact path="/profile/:profile_id" component={LoadableProfilePage} />
                  <Route exact path="/public/interviews/:job_uid/profile/:profile_uid/videos/:index" component={LoadablePublicJobCandidateVideosPage} />
                  <Route exact path="/j/:job_uid" component={LoadableJobRedirectPage} />
                  {/* <Route exact path="/jd/:job_uid" component={LoadableJobDetailPage} /> */}
                  <Route exact path="/ps/:job_uid" component={LoadablePreScreening} />
                  {/* <Route exact path="/winterview/:job_uid" component={LoadableInterviewPage} /> */}
                  <Route exact path="/i/:job_uid" component={LoadableJobDetailPage} />
                  <Route path="/signin" component={LoadableSignInPage} />
                  <Route path="/signup" component={LoadableSignUpPage} />
                  <Route path="/password/reset" component={LoadablePasswordResetPage} />
                  <Route path="/tav" component={LoadableTestAudioVideoPage} />
                  <Route path="/password/reset-confirm/:uid/:token" component={LoadablePasswordResetConfirmPage} />
                </ErrorBoundary>
              </div>
            </Router>
          </I18nextProvider>
      </div>
    );
  }
}

