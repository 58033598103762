import axios from 'axios';
import {STARS_URL} from '../constants/urlConstants';
import authHeader from '../helpers/authHeader';

const createStar = (response_id) => {
    return axios.post(STARS_URL, {response_id}, authHeader())
            .then(response=>{
                return response.data
            })
}

const fetchStars = (response_id) => {
    return axios.get(STARS_URL, authHeader({response_id}))
        .then(response => {
            return response.data;
        })
}

const fetchStarsForApplication = (job_application_uid) => {
    return axios.get(STARS_URL, authHeader({job_application_uid}))
        .then(response => {
            return response.data;
        })
}

const createStarForApplication = (job_application_uid) => {
    return axios.post(STARS_URL, {job_application_uid}, authHeader())
            .then(response=>{
                return response.data
            })
}

export const starService = {
    createStar,
    fetchStars,
    fetchStarsForApplication,
    createStarForApplication,
}