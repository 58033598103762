import axios from 'axios';
import {SIGNIN_URL, JS_SIGNUP_URL, SIGNUP_URL, PROFILE_URL, AUTH_VERIFY_URL, USERS_URL, ADD_USER_URL, VERIFY_EMAIL_URL, SET_PASSWORD_URL, RESET_PASSWORD_URL, RESET_PASSWORD_CONFIRM_URL} from '../constants/urlConstants';
import authHeader, {JSAuthHeader} from '../helpers/authHeader';
import {authRef} from '../config/firebaseConfig';

const signIn = (email, password) => {
    return axios.post(SIGNIN_URL, {email, password})
        .then(response=>{
            if(response.data.token){
                localStorage.setItem('ipuser', JSON.stringify(response.data.token));
            }
            return response.data;
        })
}

const SignUpAnonymousUser = () => {
    return authRef.signInAnonymously()
        .then(({user})=>{
            return axios.post(JS_SIGNUP_URL, { email: user.uid + '@ipass.co', uid: user.uid, first_name: '', last_name: '', avatar_url: '' })
        })
        .then(response=>{
            if(response.data.token){
                localStorage.setItem('ipjsuser', JSON.stringify(response.data.token));
            }
            return response.data;
        })
}

const signInJobSeeker = (user) => {
    return axios.post(JS_SIGNUP_URL, { email: user.email, uid: user.uid, first_name: user.displayName, last_name: '', avatar_url: user.photoURL })
        .then(response=>{
            if(response.data.token){
                localStorage.setItem('ipjsuser', JSON.stringify(response.data.token));
            }
            return response.data;
        })
}

const signOut = () => {
    localStorage.removeItem('ipuser');
}

const signJSOut = () => {
    localStorage.removeItem('ipjsuser');
}

const signUp = (first_name, last_name, email, password1, password2, phone) => {
    return axios.post(SIGNUP_URL, {first_name, last_name, email, password1, password2, phone})
        .then(response=>{
            if(response.data.token){
                localStorage.setItem('ipuser', JSON.stringify(response.data.token));
            }
            return response.data;
        })
}

const addUser = (first_name, last_name, email, invited, role) => {
    return axios.post(ADD_USER_URL, {first_name, last_name, email, invited, role}, authHeader())
        .then(response=>{
            return response.data;
        })
}

const updateJSUser = (user_id, first_name, last_name, email, phone, roll_no, department, college) => {
    return axios.patch(USERS_URL + user_id +'/', {first_name, last_name, email, phone, roll_no, department, college}, JSAuthHeader())
            .then(response=>response.data)
}

const fetchJobSeeker = (profile_id) => {
    return axios.get(PROFILE_URL + profile_id + '/', authHeader())
            .then(response=>{
                return response.data;
            })
}

const fetchUsers = () => {
    return axios.get(USERS_URL, authHeader())
            .then(response=>{
                return response.data;
            })
}

const verifyAuthToken = (token) => {
    return axios.post(AUTH_VERIFY_URL, {token})
        .then(response=>{
            return response.data;
        })
}

const verifyEmail = (key) => {
    return axios.post(VERIFY_EMAIL_URL, {key})
        .then(response=>{
                return response.data;
            })
}

const setPassword = (key, first_name, last_name, password) => {
    return axios.post(SET_PASSWORD_URL, {key,first_name, last_name, password})
        .then(response=>{
                if(response.data.token){
                    localStorage.setItem('ipuser', JSON.stringify(response.data.token));
                }
                return response.data;
            })
}

const resetPassword = (email) => {
    return axios.post(RESET_PASSWORD_URL, {email})
        .then(response=>{
                localStorage.removeItem('ipuser');
                return response.data;
            })
}

const resetPasswordConfirm = (uid, token, new_password1, new_password2) => {
    return axios.post(RESET_PASSWORD_CONFIRM_URL, {uid, token, new_password1, new_password2})
        .then(response=>{
                localStorage.removeItem('ipuser');
                return response.data;
            })
}

export const userService = {
    signIn,
    signOut,
    signJSOut,
    signUp,
    fetchJobSeeker,
    verifyAuthToken,
    fetchUsers,
    updateJSUser,
    addUser,
    verifyEmail,
    setPassword,
    resetPassword,
    resetPasswordConfirm,
    SignUpAnonymousUser,
    signInJobSeeker,
}