import {assessmentService} from '../services';
import {alertActions, userActions} from '../actions';
import { assessmentConstants } from '../constants';
import {history, js_analytics} from '../helpers';
import firebase from "@firebase/app"
import {storageRef} from '../config/firebaseConfig';
import FileDownload from 'js-file-download';

const createNewAndEditAssessment = () => {
    return dispatch => {
        dispatch(request());
        assessmentService.createAssessment()
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                history.push('/assessments/' + data.uid + '/edit')
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.CREATE_ASSESSMENT_REQUEST} }
        function success(assessment) { return { type: assessmentConstants.CREATE_ASSESSMENT_SUCCESS, assessment } }
        function failure(error) { return { type: assessmentConstants.CREATE_ASSESSMENT_FAILURE, error } }
    }
}

const cloneAndEditAssessment = (assessment_uid) => {
    return dispatch => {
        dispatch(request());
        assessmentService.cloneAssessment(assessment_uid)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                history.push(data.uid + '/edit')
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.CLONE_ASSESSMENT_REQUEST} }
        function success(assessment) { return { type: assessmentConstants.CLONE_ASSESSMENT_SUCCESS, assessment } }
        function failure(error) { return { type: assessmentConstants.CLONE_ASSESSMENT_FAILURE, error } }
    }
}

const fetchAssessments = () => {
    return dispatch => {
        dispatch(request());
        assessmentService.fetchAssessments()
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.FETCH_ASSESSMENTS_REQUEST} }
        function success(assessments) { return { type: assessmentConstants.FETCH_ASSESSMENTS_SUCCESS, assessments } }
        function failure(error) { return { type: assessmentConstants.FETCH_ASSESSMENTS_FAILURE, error } }
    }
}

const fetchAssessment = (assessment_uid) => {
    return dispatch => {
        dispatch(request());
        assessmentService.fetchAssessment(assessment_uid)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.FETCH_ASSESSMENT_REQUEST} }
        function success(assessment) { return { type: assessmentConstants.FETCH_ASSESSMENT_SUCCESS, assessment } }
        function failure(error) { return { type: assessmentConstants.FETCH_ASSESSMENT_FAILURE, error } }
    }
}
const fetchJSAssessment = (assessment_uid) => {
    return dispatch => {
        dispatch(request());
        assessmentService.fetchJSAssessment(assessment_uid)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signJSOut());
                }
            })
        function request() { return { type: assessmentConstants.FETCH_ASSESSMENT_REQUEST} }
        function success(assessment) { return { type: assessmentConstants.FETCH_ASSESSMENT_SUCCESS, assessment } }
        function failure(error) { return { type: assessmentConstants.FETCH_ASSESSMENT_FAILURE, error } }
    }
}

const updateAssessment = (assessment_id, title, status, live_date, end_date, duration_limit, due_days) => {
    return dispatch => {
        dispatch(request());
        assessmentService.updateAssessment(assessment_id, title, status, live_date, end_date, duration_limit, due_days)
            .then(data=>{
                dispatch(success(data));
                if(data.status==="active"){
                    history.push('/assessments/' + data.uid)
                }
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.UPDATE_ASSESSMENT_REQUEST} }
        function success(assessment) { return { type: assessmentConstants.UPDATE_ASSESSMENT_SUCCESS, assessment } }
        function failure(error) { return { type: assessmentConstants.UPDATE_ASSESSMENT_FAILURE, error } }
    }
}

const fetchAssessmentTemplates = () => {
    return dispatch => {
        dispatch(request());
        assessmentService.fetchAssessmentTemplates()
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.FETCH_ASSESSMENT_TEMPLATES_REQUEST} }
        function success(assessments) { return { type: assessmentConstants.FETCH_ASSESSMENT_TEMPLATES_SUCCESS, assessments } }
        function failure(error) { return { type: assessmentConstants.FETCH_ASSESSMENT_TEMPLATES_FAILURE, error } }
    }
}

const createAssessmentSubmission = (assessment_uid) => {
    return dispatch => {
        dispatch(request());
        assessmentService.createAssessmentSubmission(assessment_uid)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.CREATE_ASSESSMENT_SUBMISSION_REQUEST} }
        function success(assessment_submission) { return { type: assessmentConstants.CREATE_ASSESSMENT_SUBMISSION_SUCCESS, assessment_submission } }
        function failure(error) { return { type: assessmentConstants.CREATE_ASSESSMENT_SUBMISSION_FAILURE, error } }
    }
}

const updateAssessmentSubmission = (data, assessment_uid) => {
    return dispatch => {
        dispatch(request())
        assessmentService.updateAssessmentSubmission(data, assessment_uid)
            .then(data=>{
                dispatch(success(data));
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
            })
        function request() { return { type: assessmentConstants.UPDATE_ASSESSMENT_SUBMISSION_REQUEST} }
        function success(assessment_submission) { return { type: assessmentConstants.UPDATE_ASSESSMENT_SUBMISSION_SUCCESS, assessment_submission } }
        function failure(error) { return { type: assessmentConstants.UPDATE_ASSESSMENT_SUBMISSION_FAILURE, error } }
    }
}

const fetchAssessmentSubmissions = () => {
    return dispatch => {
        dispatch(request());
        assessmentService.fetchAssessmentSubmissions()
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.FETCH_ASSESSMENT_SUBMISSIONS_REQUEST} }
        function success(assessment_submissions) { return { type: assessmentConstants.FETCH_ASSESSMENT_SUBMISSIONS_SUCCESS, assessment_submissions } }
        function failure(error) { return { type: assessmentConstants.FETCH_ASSESSMENT_SUBMISSIONS_FAILURE, error } }
    }
}

const fetchAssessmentCandidate = (assessment_uid, profile_uid) => {
    return dispatch => {
        dispatch(request());
        assessmentService.fetchAssessmentCandidate(assessment_uid, profile_uid)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.FETCH_ASSESSMENT_CANDIDATE_REQUEST} }
        function success(assessment_candidate) { return { type: assessmentConstants.FETCH_ASSESSMENT_CANDIDATE_SUCCESS, assessment_candidate } }
        function failure(error) { return { type: assessmentConstants.FETCH_ASSESSMENT_CANDIDATE_FAILURE, error } }
    }
}

const clearAssessmentCandidate = () => {
    return dispatch => {
        dispatch(success({results: []}));
        function success(assessment_candidate) { return { type: assessmentConstants.CLEAR_ASSESSMENT_CANDIDATE_SUCCESS, assessment_candidate } }
    }
}

const fetchAssessmentCandidates = (assessment_uid, application_status, review_status, order_by) => {
    return dispatch => {
        dispatch(request());
        assessmentService.fetchAssessmentCandidates(assessment_uid, application_status, review_status, order_by)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.FETCH_ASSESSMENT_CANDIDATES_REQUEST} }
        function success(assessment_candidates) { return { type: assessmentConstants.FETCH_ASSESSMENT_CANDIDATES_SUCCESS, assessment_candidates } }
        function failure(error) { return { type: assessmentConstants.FETCH_ASSESSMENT_CANDIDATES_FAILURE, error } }
    }
}

const fetchMoreAssessmentCandidates = (next_url) => {
    return dispatch => {
        dispatch(request());
        assessmentService.fetchMoreAssessmentCandidates(next_url)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.FETCH_MORE_ASSESSMENT_CANDIDATES_REQUEST} }
        function success(assessment_candidates) { return { type: assessmentConstants.FETCH_MORE_ASSESSMENT_CANDIDATES_SUCCESS, assessment_candidates } }
        function failure(error) { return { type: assessmentConstants.FETCH_MORE_ASSESSMENT_CANDIDATES_FAILURE, error } }
    }
}


const orderAssessmentQuestion = (assessment_uid, assessment_question_id, old_index, new_index) => {
    return dispatch => {
        dispatch(request());
        assessmentService.orderAssessmentQuestion(assessment_uid, assessment_question_id, old_index, new_index)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.ORDER_ASSESSMENT_QUESTION_REQUEST} }
        function success(questions) { return { type: assessmentConstants.ORDER_ASSESSMENT_QUESTION_SUCCESS, questions } }
        function failure(error) { return { type: assessmentConstants.ORDER_ASSESSMENT_QUESTION_FAILURE, error } }
    }
}

const createAssessmentQuestion = (question_title, question_type, question_description, assessment_id, question_range, question_choices, media_type, answer_key, positive_score, negative_score, is_mandatory) => {
    return dispatch => {
        dispatch(request());
        assessmentService.createAssessmentQuestion(question_title, question_type, question_description, assessment_id, question_range, question_choices, media_type, answer_key, positive_score, negative_score, is_mandatory)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.CREATE_ASSESSMENT_QUESTION_REQUEST} }
        function success(assessment_question) { return { type: assessmentConstants.CREATE_ASSESSMENT_QUESTION_SUCCESS, assessment_question } }
        function failure(error) { return { type: assessmentConstants.CREATE_ASSESSMENT_QUESTION_FAILURE, error } }
    }
}

const updateAssessmentQuestion = (assessment_question_id, question_title, question_type, question_description, question_range, question_choices, media_type, answer_key, positive_score, negative_score, is_mandatory) => {
    return dispatch => {
        dispatch(request());
        assessmentService.updateAssessmentQuestion(assessment_question_id, question_title, question_type, question_description, question_range, question_choices, media_type, answer_key, positive_score, negative_score, is_mandatory)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.UPDATE_ASSESSMENT_QUESTION_REQUEST} }
        function success(assessment_question) { return { type: assessmentConstants.UPDATE_ASSESSMENT_QUESTION_SUCCESS, assessment_question } }
        function failure(error) { return { type: assessmentConstants.UPDATE_ASSESSMENT_QUESTION_FAILURE, error } }
    }
}

const deleteAssessmentQuestion = (assessment_question_id) => {
    return dispatch => {
        dispatch(request());
        assessmentService.deleteAssessmentQuestion(assessment_question_id)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.DELETE_ASSESSMENT_QUESTION_REQUEST} }
        function success(questions) { return { type: assessmentConstants.DELETE_ASSESSMENT_QUESTION_SUCCESS, questions} }
        function failure(error) { return { type: assessmentConstants.DELETE_ASSESSMENT_QUESTION_FAILURE, error } }
    }
}

const createResponse = (question_id, value, assessment_id) =>{
    return dispatch => {
        dispatch(request())
        assessmentService.createResponse(question_id, value, assessment_id)
            .then(data=>{
                js_analytics.track("response_added",{question_id, status:'completed', assessment_id:assessment_id,});
                dispatch(success(data));
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
            })
        function request() { return { type: assessmentConstants.CREATE_ASSESSMENT_RESPONSE_REQUEST} }
        function success(response) { return { type: assessmentConstants.CREATE_ASSESSMENT_RESPONSE_SUCCESS, response } }
        function failure(error) { return { type: assessmentConstants.CREATE_ASSESSMENT_RESPONSE_FAILURE, error } }
    }
}

const createFileResponse = (question_id, file, assessment_id) =>{
    return dispatch => {
        dispatch(request())
        assessmentService.createFileResponse(question_id, file, assessment_id)
            .then(data=>{
                js_analytics.track("response_added",{question_id, status:'completed', assessment_id:assessment_id,});
                dispatch(success(data));
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
            })
        function request() { return { type: assessmentConstants.CREATE_ASSESSMENT_RESPONSE_REQUEST} }
        function success(response) { return { type: assessmentConstants.CREATE_ASSESSMENT_RESPONSE_SUCCESS, response } }
        function failure(error) { return { type: assessmentConstants.CREATE_ASSESSMENT_RESPONSE_FAILURE, error } }
    }
}

const createVideoResponse = (question_id, file, assessment_id) =>{
    return dispatch => {
        dispatch(request())
        let blob_url = URL.createObjectURL(file, {type:'video/webm'});
        let file_name = blob_url.substring(blob_url.lastIndexOf('/')+1) + '.webm';
        console.log("filename:", file_name)
        assessmentService.createVideoResponse(question_id, "", assessment_id, 'in_progress', {local_video_url: blob_url, fb_video_url: file_name})
            .then(data=>{
                js_analytics.track("upload_started",{question_id, status:'in_progress', assessment_id:assessment_id,});
                dispatch(success(data));
                try {
                    const videosRef = storageRef.child('profile-videos')
                    let uploadTask = videosRef.child(file_name).put(file, { 'contentType': 'webm' });
                    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                    function (snapshot) {
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('[Firebase Upload] progress:' + progress);
                        dispatch(update_progress(question_id, progress))
                        // store.dispatch(setSaveVideoForQuestionProgress(video.uri, progress))
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED:
                                console.log('[Firebase Upload] Upload is paused');
                                break;
                            case firebase.storage.TaskState.RUNNING:
                                console.log('[Firebase Upload] Upload is running');
                                break;
                            case firebase.storage.TaskState.SUCCESS:
                                console.log('[Firebase Upload] Upload is success');
                                break;
                            case firebase.storage.TaskState.CANCELED:
                                console.log('[Firebase Upload] Upload is canceled');
                                break;
                            case firebase.storage.TaskState.ERROR:
                                console.log('[Firebase Upload] Upload is errored');
                                // store.dispatch(setSaveVideoForQuestionFailure('Video upload error', video.uri));
                                break;
                            default:
                                break;
                        }
                    },
                    function (error) {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        console.log('[Firebase Upload] Error', error);
                        // store.dispatch(setSaveVideoForQuestionFailure(error.code, video.uri));
                        js_analytics.track("upload_started",{question_id, status:'upload_failed', assessment_id:assessment_id, error});
                        switch (error.code) {
                            case 'storage/unauthorized':
                                console.log('[Firebase Upload] Storage Unauthorized Error', error.code);
                                // User doesn't have permission to access the object
                                break;

                            case 'storage/canceled':
                                console.log('[Firebase Upload] Storage Canceled', error.code);
                                // User canceled the upload
                                break;

                            case 'storage/unknown':
                                console.log('[Firebase Upload] Storage Unknown error', error.code);
                                // Unknown error occurred, inspect error.serverResponse
                                break;
                            default:
                                break;
                        }
                    },
                    function(){
                        js_analytics.track("upload_started",{question_id, status:'upload_completed', assessment_id:assessment_id,});
                        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                            console.log('File available at', downloadURL);
                        });
                        assessmentService.createVideoResponse(question_id, "", assessment_id, 'completed',{})
                            .then(data=>{
                                dispatch(success(data));
                            })
                    });

                } catch (err) {
                    console.log("[upload-video-worker] Error: ", err)
                    // store.dispatch(setSaveVideoForQuestionFailure(err, payload['video'].uri));
                }
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
            })
        function request() { return { type: assessmentConstants.CREATE_ASSESSMENT_VIDEO_RESPONSE_REQUEST} }
        function success(response) { return { type: assessmentConstants.CREATE_ASSESSMENT_VIDEO_RESPONSE_SUCCESS, response } }
        function update_progress(question_id, progress) { return { type: assessmentConstants.CREATE_ASSESSMENT_VIDEO_RESPONSE_PROGRESS, question_id, progress } }
        function failure(error) { return { type: assessmentConstants.CREATE_ASSESSMENT_VIDEO_RESPONSE_FAILURE, error } }
    }
}

const sendInvites = (data) => {
    return dispatch => {
        dispatch(request());
        assessmentService.sendInvites(data)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.success('Successfully uploaded, Invites will be sent to the candidates shortly.', 'SEND_INVITES'));
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.SEND_ASSESSMENT_INVITES_REQUEST} }
        function success(invites) { return { type: assessmentConstants.SEND_ASSESSMENT_INVITES_SUCCESS, invites } }
        function failure(error) { return { type: assessmentConstants.SEND_ASSESSMENT_INVITES_FAILURE, error } }
    }
}

const addAssessmentReviewers = (assessment_id, user_id) => {
    return dispatch => {
        dispatch(request());
        assessmentService.addAssessmentReviewers(assessment_id, user_id)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.ADD_ASSESSMENT_REVIEWERS_REQUEST} }
        function success(reviewer) { return { type: assessmentConstants.ADD_ASSESSMENT_REVIEWERS_SUCCESS, reviewer } }
        function failure(error) { return { type: assessmentConstants.ADD_ASSESSMENT_REVIEWERS_FAILURE, error } }
    }
}

const downloadScores = (assessment_uid) => {
    return dispatch => {
        dispatch(request());
        assessmentService.downloadScores(assessment_uid)
            .then(data=>{
                let format = (date) => {
                    var day = date.getDate();
                    var monthIndex = date.getMonth();
                    var year = date.getFullYear();
                    return year + "_" + monthIndex + "_" + day;
                }
                FileDownload(data.data, "scores_" + format(new Date()) + '.csv', "text/csv");
                dispatch(success(data));
                dispatch(alertActions.clear());
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.DOWNLOAD_ASSESSMENT_REPORT_REQUEST} }
        function success(report) { return { type: assessmentConstants.DOWNLOAD_ASSESSMENT_REPORT_SUCCESS, report } }
        function failure(error) { return { type: assessmentConstants.DOWNLOAD_ASSESSMENT_REPORT_FAILURE, error } }
    }
}

const downloadRubrics = (assessment_uid) => {
    return dispatch => {
        dispatch(request());
        assessmentService.downloadRubrics(assessment_uid)
            .then(data=>{
                let format = (date) => {
                    var day = date.getDate();
                    var monthIndex = date.getMonth();
                    var year = date.getFullYear();
                    return year + "_" + monthIndex + "_" + day;
                }
                FileDownload(data.data, "rubrics_" + format(new Date()) + '.csv', "text/csv");
                dispatch(success(data));
                dispatch(alertActions.clear());
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.DOWNLOAD_ASSESSMENT_REPORT_REQUEST} }
        function success(report) { return { type: assessmentConstants.DOWNLOAD_ASSESSMENT_REPORT_SUCCESS, report } }
        function failure(error) { return { type: assessmentConstants.DOWNLOAD_ASSESSMENT_REPORT_FAILURE, error } }
    }
}

const downloadSubmissions = (assessment_uid) => {
    return dispatch => {
        dispatch(request());
        assessmentService.downloadSubmissions(assessment_uid)
            .then(data=>{
                let format = (date) => {
                    var day = date.getDate();
                    var monthIndex = date.getMonth();
                    var year = date.getFullYear();
                    return year + "_" + monthIndex + "_" + day;
                }
                FileDownload(data.data, "submissions_" + format(new Date()) + '.csv', "text/csv");
                dispatch(success(data));
                dispatch(alertActions.clear());
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: assessmentConstants.DOWNLOAD_ASSESSMENT_REPORT_REQUEST} }
        function success(report) { return { type: assessmentConstants.DOWNLOAD_ASSESSMENT_REPORT_SUCCESS, report } }
        function failure(error) { return { type: assessmentConstants.DOWNLOAD_ASSESSMENT_REPORT_FAILURE, error } }
    }
}
export const assessmentActions = {
    createNewAndEditAssessment,
    cloneAndEditAssessment,
    fetchAssessments,
    fetchAssessment,
    fetchJSAssessment,
    updateAssessment,
    fetchAssessmentTemplates,
    fetchAssessmentCandidate,
    clearAssessmentCandidate,
    fetchAssessmentCandidates,
    fetchMoreAssessmentCandidates,
    orderAssessmentQuestion,
    createAssessmentQuestion,
    updateAssessmentQuestion,
    deleteAssessmentQuestion,
    fetchAssessmentSubmissions,
    createAssessmentSubmission,
    updateAssessmentSubmission,
    createResponse,
    createFileResponse,
    createVideoResponse,
    sendInvites,
    addAssessmentReviewers,
    downloadScores,
    downloadRubrics,
    downloadSubmissions,
}
