export const openingConstants = {
    CREATE_JOBAPPLICATION_REQUEST: 'CREATE_JOBAPPLICATION_REQUEST',
    CREATE_JOBAPPLICATION_SUCCESS: 'CREATE_JOBAPPLICATION_SUCCESS',
    CREATE_JOBAPPLICATION_FAILURE: 'CREATE_JOBAPPLICATION_FAILURE',
    UPDATE_JOBAPPLICATION_REQUEST: 'UPDATE_JOBAPPLICATION_REQUEST',
    UPDATE_JOBAPPLICATION_SUCCESS: 'UPDATE_JOBAPPLICATION_SUCCESS',
    UPDATE_JOBAPPLICATION_FAILURE: 'UPDATE_JOBAPPLICATION_FAILURE',
    UPDATE_JS_JOBAPPLICATION_REQUEST: 'UPDATE_JS_JOBAPPLICATION_REQUEST',
    UPDATE_JS_JOBAPPLICATION_SUCCESS: 'UPDATE_JS_JOBAPPLICATION_SUCCESS',
    UPDATE_JS_JOBAPPLICATION_FAILURE: 'UPDATE_JS_JOBAPPLICATION_FAILURE',
    CREATE_RESPONSE_REQUEST: 'CREATE_RESPONSE_REQUEST',
    CREATE_RESPONSE_SUCCESS: 'CREATE_RESPONSE_SUCCESS',
    CREATE_RESPONSE_FAILURE: 'CREATE_RESPONSE_FAILURE',
    CREATE_VIDEO_RESPONSE_REQUEST: 'CREATE_VIDEO_RESPONSE_REQUEST',
    CREATE_VIDEO_RESPONSE_SUCCESS: 'CREATE_VIDEO_RESPONSE_SUCCESS',
    CREATE_VIDEO_RESPONSE_FAILURE: 'CREATE_VIDEO_RESPONSE_FAILURE',
    CREATE_VIDEO_RESPONSE_PROGRESS: 'CREATE_VIDEO_RESPONSE_PROGRESS',
    FETCH_PRESCREENING_QUESTIONS_REQUEST: 'FETCH_PRESCREENING_QUESTIONS_REQUEST',
    FETCH_PRESCREENING_QUESTIONS_SUCCESS: 'FETCH_PRESCREENING_QUESTIONS_SUCCESS',
    FETCH_PRESCREENING_QUESTIONS_FAILURE: 'FETCH_PRESCREENING_QUESTIONS_FAILURE',
    CREATE_JOBOPENING_REQUEST: 'CREATE_JOBOPENING_REQUEST',
    CREATE_JOBOPENING_SUCCESS: 'CREATE_JOBOPENING_SUCCESS',
    CREATE_JOBOPENING_FAILURE: 'CREATE_JOBOPENING_FAILURE',
    CLEAR_JOBOPENING_SUCCESS: 'CLEAR_JOBOPENING_SUCCESS',
    UPDATE_JOBOPENING_REQUEST: 'UPDATE_JOBOPENING_REQUEST',
    UPDATE_JOBOPENING_SUCCESS: 'UPDATE_JOBOPENING_SUCCESS',
    UPDATE_JOBOPENING_FAILURE: 'UPDATE_JOBOPENING_FAILURE',
    ADD_JOBOPENING_REVIEWERS_REQUEST: 'ADD_JOBOPENING_REVIEWERS_REQUEST',
    ADD_JOBOPENING_REVIEWERS_SUCCESS: 'ADD_JOBOPENING_REVIEWERS_SUCCESS',
    ADD_JOBOPENING_REVIEWERS_FAILURE: 'ADD_JOBOPENING_REVIEWERS_FAILURE',
    UPDATE_JOBOPENING_STATUS_REQUEST: 'UPDATE_JOBOPENING_STATUS_REQUEST',
    UPDATE_JOBOPENING_STATUS_SUCCESS: 'UPDATE_JOBOPENING_STATUS_SUCCESS',
    UPDATE_JOBOPENING_STATUS_FAILURE: 'UPDATE_JOBOPENING_STATUS_FAILURE',
    CREATE_JOBOPENING_QUESTION_REQUEST: 'CREATE_JOBOPENING_QUESTION_REQUEST',
    CREATE_JOBOPENING_QUESTION_SUCCESS: 'CREATE_JOBOPENING_QUESTION_SUCCESS',
    CREATE_JOBOPENING_QUESTION_FAILURE: 'CREATE_JOBOPENING_QUESTION_FAILURE',
    UPDATE_JOB_DESCRIPTION_REQUEST: 'UPDATE_JOB_DESCRIPTION_REQUEST',
    UPDATE_JOB_DESCRIPTION_SUCCESS: 'UPDATE_JOB_DESCRIPTION_SUCCESS',
    UPDATE_JOB_DESCRIPTION_FAILURE: 'UPDATE_JOB_DESCRIPTION_FAILURE',
    FETCH_JOBOPENING_REQUEST: 'FETCH_JOBOPENING_REQUEST',
    FETCH_JOBOPENING_SUCCESS: 'FETCH_JOBOPENING_SUCCESS',
    FETCH_JOBOPENING_FAILURE: 'FETCH_JOBOPENING_FAILURE',
    FETCH_JOBOPENING_FILTERS_REQUEST: 'FETCH_JOBOPENING_FILTERS_REQUEST',
    FETCH_JOBOPENING_FILTERS_SUCCESS: 'FETCH_JOBOPENING_FILTERS_SUCCESS',
    FETCH_JOBOPENING_FILTERS_FAILURE: 'FETCH_JOBOPENING_FILTERS_FAILURE',
    FETCH_JOBOPENINGS_REQUEST: 'FETCH_JOBOPENINGS_REQUEST',
    FETCH_JOBOPENINGS_SUCCESS: 'FETCH_JOBOPENINGS_SUCCESS',
    FETCH_JOBOPENINGS_FAILURE: 'FETCH_JOBOPENINGS_FAILURE',
    FETCH_JOBFUNCTIONS_REQUEST: 'FETCH_JOBFUNCTIONS_REQUEST',
    FETCH_JOBFUNCTIONS_SUCCESS: 'FETCH_JOBFUNCTIONS_SUCCESS',
    FETCH_JOBFUNCTIONS_FAILURE: 'FETCH_JOBFUNCTIONS_FAILURE',
    FETCH_JOBCANDIDATES_REQUEST: 'FETCH_JOBCANDIDATES_REQUEST',
    FETCH_JOBCANDIDATES_SUCCESS: 'FETCH_JOBCANDIDATES_SUCCESS',
    FETCH_JOBCANDIDATES_FAILURE: 'FETCH_JOBCANDIDATES_FAILURE',
    FETCH_MORE_JOBCANDIDATES_REQUEST: 'FETCH_MORE_JOBCANDIDATES_REQUEST',
    FETCH_MORE_JOBCANDIDATES_SUCCESS: 'FETCH_MORE_JOBCANDIDATES_SUCCESS',
    FETCH_MORE_JOBCANDIDATES_FAILURE: 'FETCH_MORE_JOBCANDIDATES_FAILURE',
    FETCH_JOBCANDIDATES_LIST_REQUEST: 'FETCH_JOBCANDIDATES_LIST_REQUEST',
    FETCH_JOBCANDIDATES_LIST_SUCCESS: 'FETCH_JOBCANDIDATES_LIST_SUCCESS',
    FETCH_JOBCANDIDATES_LIST_FAILURE: 'FETCH_JOBCANDIDATES_LIST_FAILURE',
    FETCH_JOBCANDIDATE_REQUEST: 'FETCH_JOBCANDIDATE_REQUEST',
    FETCH_JOBCANDIDATE_SUCCESS: 'FETCH_JOBCANDIDATE_SUCCESS',
    FETCH_JOBCANDIDATE_FAILURE: 'FETCH_JOBCANDIDATE_FAILURE',
    UPDATE_FILTER: 'UPDATE_FITLER',
    MARK_QUESTION_EDIT_REQUEST: 'MARK_QUESTION_EDIT_REQUEST',
    MARK_QUESTION_EDIT_SUCCESS: 'MARK_QUESTION_EDIT_SUCCESS',
    MARK_QUESTION_EDIT_FAILURE: 'MARK_QUESTION_EDIT_FAILURE',
    UNMARK_QUESTION_EDIT_REQUEST: 'UNMARK_QUESTION_EDIT_REQUEST',
    UNMARK_QUESTION_EDIT_SUCCESS: 'UNMARK_QUESTION_EDIT_SUCCESS',
    UNMARK_QUESTION_EDIT_FAILURE: 'UNMARK_QUESTION_EDIT_FAILURE',
    UPDATE_QUESTION_REQUEST: 'UPDATE_QUESTION_REQUEST',
    UPDATE_QUESTION_FAILURE: 'UPDATE_QUESTION_FAILURE',
    UPDATE_QUESTION_SUCCESS: 'UPDATE_QUESTION_SUCCESS',
    UPDATE_QUESTION_STATE_REQUEST: 'UPDATE_QUESTION_STATE_REQUEST',
    UPDATE_QUESTION_STATE_SUCCESS: 'UPDATE_QUESTION_STATE_SUCCESS',
    DELETE_QUESTION_REQUEST: 'DELETE_QUESTION_REQUEST',
    DELETE_QUESTION_SUCCESS: 'DELETE_QUESTION_SUCCESS',
    DELETE_QUESTION_FAILURE: 'DELETE_QUESTION_FAILURE',
    ORDER_QUESTION_REQUEST: 'ORDER_QUESTION_REQUEST',
    ORDER_QUESTION_SUCCESS: 'ORDER_QUESTION_SUCCESS',
    ORDER_QUESTION_FAILURE: 'ORDER_QUESTION_FAILURE',
    FETCH_JOBOPENINGS_BY_ID_REQUEST: 'FETCH_JOBOPENINGS_BY_ID_REQUEST',
    FETCH_JOBOPENINGS_BY_ID_SUCCESS: 'FETCH_JOBOPENINGS_BY_ID_SUCCESS',
    FETCH_JOBOPENINGS_BY_ID_FAILURE: 'FETCH_JOBOPENINGS_BY_ID_FAILURE',
    SEND_INVITES_REQUEST: 'SEND_INVITES_REQUEST',
    SEND_INVITES_SUCCESS: 'SEND_INVITES_SUCCESS',
    SEND_INVITES_FAILURE: 'SEND_INVITES_FAILURE',
    CHANGE_STATUS: 'CHANGE_STATUS',
    CLEAR_APPLICATION: 'CLEAR_APPLICATION',
}