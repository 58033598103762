import axios from 'axios';
import {ASSESSMENTS_URL, JS_ASSESSMENTS_URL, ASSESSMENT_TEMPLATES_URL, ASSESSMENT_REVIEWERS_URL, ASSESSMENT_QUESTIONS_URL, ASSESSMENT_SUBMISSIONS_URL, RESPONSE_URL, ASSESSMENT_CANDIDATES_URL, ASSESSMENT_INVITEES_URL, ASSESSMENT_SCORE_REPORT_URL, ASSESSMENT_RUBRICS_REPORT_URL, ASSESSMENT_SUBMISSIONS_REPORT_URL} from '../constants/urlConstants';
import authHeader, {JSAuthHeader} from '../helpers/authHeader';

const createAssessment = () => {
    return axios.post(ASSESSMENTS_URL,{}, authHeader())
            .then(response=>{
                return response.data
            })
}

const cloneAssessment = (assessment_uid) => {
    return axios.post(ASSESSMENTS_URL + assessment_uid + '/clone/', {}, authHeader())
            .then(response=>{
                return response.data
            })
}

const fetchAssessments = () => {
    return axios.get(ASSESSMENTS_URL, authHeader({fields: 'uid,title,status'}))
        .then(response => {
            return response.data;
        })
}

const fetchAssessment = (assessment_uid) => {
    return axios.get(ASSESSMENTS_URL + assessment_uid + '/', authHeader())
        .then(response => {
            return response.data;
        })
}

const fetchJSAssessment = (assessment_uid) => {
    return axios.get(JS_ASSESSMENTS_URL + assessment_uid + '/', JSAuthHeader())
        .then(response => {
            return response.data;
        })
}

const updateAssessment = (assessment_id, title, status, live_date, end_date, duration_limit, due_days) => {
    return axios.patch(ASSESSMENTS_URL + assessment_id + '/', {title, status, live_date, end_date, duration_limit, due_days}, authHeader())
        .then(response => {
            return response.data;
        })
}

const fetchAssessmentTemplates = () => {
    return axios.get(ASSESSMENT_TEMPLATES_URL, authHeader())
        .then(response => {
            return response.data;
        })
}

const createAssessmentSubmission = (assessment) => {
    return axios.post(ASSESSMENT_SUBMISSIONS_URL, {assessment}, JSAuthHeader())
        .then(response=>response.data);
}

const updateAssessmentSubmission = (data, assessment_uid) => {
    return axios.patch(ASSESSMENT_SUBMISSIONS_URL + assessment_uid +'/', data , JSAuthHeader())
            .then(response=>{
                return response.data
            })
}

const fetchAssessmentSubmissions = () => {
    return axios.get(ASSESSMENT_SUBMISSIONS_URL, JSAuthHeader())
        .then(response => {
            return response.data;
        })
}

const fetchAssessmentCandidate = (assessment_uid, profile_uid) => {
    const searchParams = new URLSearchParams();
    const search = {assessment_uid, profile_uid};
    Object.keys(search).forEach(key => searchParams.append(key, search[key]));
    let query = searchParams.toString()
    return axios.get(ASSESSMENT_CANDIDATES_URL + '?'+ query,  authHeader())
            .then(response=> response.data);
}

const fetchAssessmentCandidates = (assessment_uid, application_status, review_status, order_by) => {
    const searchParams = new URLSearchParams();
    const search = {assessment_uid, application_status, review_status, order_by};
    Object.keys(search).forEach(key => searchParams.append(key, search[key]));
    let query = searchParams.toString()
    return axios.get(ASSESSMENT_CANDIDATES_URL + '?'+ query,  authHeader())
            .then(response=> response.data);
}

const fetchMoreAssessmentCandidates = (url) => {
    return axios.get(process.env.NODE_ENV === 'production' ? url.replace('http://', 'https://') : url, authHeader())
        .then(response => {
            return response.data;
        })
}

const orderAssessmentQuestion = (assessment_uid, assessment_question_id, old_index, new_index) => {
    return axios.patch(ASSESSMENTS_URL + assessment_uid + '/qorder/', {assessment_question_id, old_index, new_index},authHeader())
            .then(response=>response.data)
}

const createAssessmentQuestion = (question_title, question_type, question_description, assessment_id, question_range, question_choices, media_type, answer_key, positive_score, negative_score, is_mandatory) => {
    return axios.post(ASSESSMENT_QUESTIONS_URL, {question_title, question_type, question_description, assessment:assessment_id, question_range, question_choices, media_type, answer_key, positive_score, negative_score, is_mandatory}, authHeader())
            .then(response=>response.data)
}

const updateAssessmentQuestion = (assessment_question_id, question_title, question_type, question_description, question_range, question_choices, media_type, answer_key, positive_score, negative_score, is_mandatory) => {
    return axios.patch(ASSESSMENT_QUESTIONS_URL + assessment_question_id + '/', {question_title, question_type, question_description, question_range, question_choices, media_type, answer_key, positive_score, negative_score, is_mandatory}, authHeader())
            .then(response=>{
                return response.data
            })
}

const deleteAssessmentQuestion = (assessment_question_id) => {
    return axios.delete(ASSESSMENT_QUESTIONS_URL + assessment_question_id + '/', authHeader())
            .then(response=>response.data)
}

const createResponse = (question_id, value, assessment_id) =>{
    let post_data = { interaction: question_id, transcript: 'default transcript', upload_status:'completed', value:value, assessment_id: assessment_id}
    return axios.post(RESPONSE_URL, post_data, JSAuthHeader())
            .then(response=>{
                return response.data
            })
}

const createVideoResponse = (question_id, value, assessment_id, status, data) => {
    let post_data = { interaction: question_id, transcript: 'default transcript', upload_status:status, value:value, assessment_id: assessment_id, ...data}
    return axios.post(RESPONSE_URL, post_data, JSAuthHeader())
            .then(response=>{
                return response.data
            })
}

const createFileResponse = (question_id, file, assessment_id) =>{
    let formData = new FormData();
    formData.append('file',file)
    formData.append('interaction', question_id);
    formData.append('transcript', 'default transcript');
    formData.append('assessment_id', assessment_id)
    formData.append('upload_status', 'completed')
    return axios.post(RESPONSE_URL, formData, JSAuthHeader({'Content-Type': 'multipart/form-data'}))
            .then((response)=>{
                return response.data
            })
}

const sendInvites = (data) => {
    return axios.post(ASSESSMENT_INVITEES_URL, [...data], authHeader())
                .then(response=>response.data)
}

const addAssessmentReviewers = (assessment_id, user_id) => {
    return axios.post(ASSESSMENT_REVIEWERS_URL, {assessment:assessment_id, user:user_id}, authHeader())
    .then(response=>{
        return response.data
    })
}

const downloadSubmissions = (assessment_uid) => {
    return axios.get(ASSESSMENT_SCORE_REPORT_URL + assessment_uid + '/', authHeader())
    .then(response=>{
        return response.data
    })
}
const downloadRubrics = (assessment_uid) => {
    return axios.get(ASSESSMENT_RUBRICS_REPORT_URL + assessment_uid + '/', authHeader())
    .then(response=>{
        return response.data
    })
}
const downloadScores = (assessment_uid) => {
    return axios.get(ASSESSMENT_SUBMISSIONS_REPORT_URL + assessment_uid + '/', authHeader())
    .then(response=>{
        return response.data
    })
}

export const assessmentService = {
    createAssessment,
    cloneAssessment,
    fetchAssessments,
    fetchAssessment,
    fetchJSAssessment,
    updateAssessment,
    fetchAssessmentCandidate,
    fetchAssessmentCandidates,
    fetchMoreAssessmentCandidates,
    fetchAssessmentTemplates,
    orderAssessmentQuestion,
    deleteAssessmentQuestion,
    createAssessmentQuestion,
    updateAssessmentQuestion,
    fetchAssessmentSubmissions,
    createAssessmentSubmission,
    updateAssessmentSubmission,
    createResponse,
    createFileResponse,
    createVideoResponse,
    sendInvites,
    addAssessmentReviewers,
    downloadScores,
    downloadRubrics,
    downloadSubmissions,
}
