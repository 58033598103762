import {connect} from 'react-redux';
import PrivateLayout from './PrivateLayout'
import {userActions} from '../../actions/userActions';
import {history} from '../../helpers/history';


const mapStateToProps = (state) => ({
    current_user: state.authentication && state.authentication.user,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    goTo: (key) => {
        history.push('/' + key)
    },
    verifyAuthToken: () => {
        return dispatch(userActions.verifyAuthToken());
    },
    signOut: () => {
        return dispatch(userActions.signOut())
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateLayout);