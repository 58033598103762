import { starConstants } from '../constants';

// let user = JSON.parse(localStorage.getItem('user'));
const initialState = {stars:[]};

export const starReducer = (state = initialState, action) => {
    switch (action.type) {
    case starConstants.CREATE_APPLICATION_STAR_SUCCESS:
    case starConstants.CREATE_STAR_SUCCESS:
        return Object.assign({}, state, {
            stars: state.stars.concat(action.star)
        });
    case starConstants.FETCH_APPLICATION_STARS_SUCCESS:
    case starConstants.FETCH_STARS_SUCCESS:
        return Object.assign({}, state, {
            stars: action.stars
        });
    default:
        return state
    }
}