export const noteConstants = {
    CREATE_NOTE_REQUEST: 'CREATE_NOTE_REQUEST',
    CREATE_NOTE_SUCCESS: 'CREATE_NOTE_SUCCESS',
    CREATE_NOTE_FAILURE: 'CREATE_NOTE_FAILURE',
    FETCH_NOTES_REQUEST: 'FETCH_NOTES_REQUEST',
    FETCH_NOTES_SUCCESS: 'FETCH_NOTES_SUCCESS',
    FETCH_NOTES_FAILURE: 'FETCH_NOTES_FAILURE',
    SET_SELECTED_RESPONSE: 'SET_SELECTED_RESPONSE',
    FETCH_APPLICATION_NOTES_REQUEST: 'FETCH_APPLICATION_NOTES_REQUEST',
    FETCH_APPLICATION_NOTES_SUCCESS: 'FETCH_APPLICATION_NOTES_SUCCESS',
    FETCH_APPLICATION_NOTES_FAILURE: 'FETCH_APPLICATION_NOTES_FAILURE',
    CREATE_APPLICATION_NOTE_REQUEST: 'CREATE_APPLICATION_NOTE_REQUEST',
    CREATE_APPLICATION_NOTE_SUCCESS: 'CREATE_APPLICATION_NOTE_SUCCESS',
    CREATE_APPLICATION_NOTE_FAILURE: 'CREATE_APPLICATION_NOTE_FAILURE',
    FETCH_ASSESSMENT_SUBMISSION_NOTES_REQUEST: 'FETCH_ASSESSMENT_SUBMISSION_NOTES_REQUEST',
    FETCH_ASSESSMENT_SUBMISSION_NOTES_SUCCESS: 'FETCH_ASSESSMENT_SUBMISSION_NOTES_SUCCESS',
    FETCH_ASSESSMENT_SUBMISSION_NOTES_FAILURE: 'FETCH_ASSESSMENT_SUBMISSION_NOTES_FAILURE',
    CREATE_ASSESSMENT_SUBMISSION_NOTE_REQUEST: 'CREATE_ASSESSMENT_SUBMISSION_NOTE_REQUEST',
    CREATE_ASSESSMENT_SUBMISSION_NOTE_SUCCESS: 'CREATE_ASSESSMENT_SUBMISSION_NOTE_SUCCESS',
    CREATE_ASSESSMENT_SUBMISSION_NOTE_FAILURE: 'CREATE_ASSESSMENT_SUBMISSION_NOTE_FAILURE',
}