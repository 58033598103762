import { templateConstants } from '../constants';

// let user = JSON.parse(localStorage.getItem('user'));
const initialState = {categories:[], templates:[]};

export const templateReducer = (state = initialState, action) => {
    switch (action.type) {
    case templateConstants.FETCH_CATEGORIES_SUCCESS:
        return Object.assign({}, state, {
            categories: action.categories
        });
    case templateConstants.FETCH_TEMPLATES_SUCCESS:
        return Object.assign({}, state, {
            templates: action.templates
        });
    default:
        return state
    }
}