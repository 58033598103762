
import { combineReducers } from 'redux';

import { authentication } from './authenticationReducer';
import {job_seeker} from './jobSeekerReducer';
import {job_opening} from './jobOpeningReducer'
import { alert } from './alertReducer';
import loadingReducer from './loadingReducer';
import { noteReducer } from './noteReducer';
import { starReducer } from './starReducer';
import { uiReducer } from './uiReducer';
import {reviewReducer} from './reviewReducer';
import {scoreReducer} from './scoreReducer';
import {subscriptionReducer} from './subscriptionReducer';
import {assessmentReducer} from './assessmentReducer';
import {templateReducer} from './templateReducer';

const AppReducers = combineReducers({
  authentication,
  alert,
  job_seeker,
  job_opening,
  loading: loadingReducer,
  notes: noteReducer,
  stars:starReducer,
  scores: scoreReducer,
  reviews: reviewReducer,
  subscriptions: subscriptionReducer,
  assessments: assessmentReducer,
  templates: templateReducer,
  ui:uiReducer,
});

const rootReducer = (state,action) => {
  if(action.type === 'USERS_SIGNOUT'){
    state = undefined
  }
  return AppReducers(state,action);
}

export default rootReducer;