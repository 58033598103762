const getToken = () => {
    return JSON.parse(localStorage.getItem('ipuser'));
}
const getJSToken = () => {
    return JSON.parse(localStorage.getItem('ipjsuser'));
}

export const isLoggedIn = () => {
    return !!getToken();
}

export const isJSLoggedIn = () => {
    return !! getJSToken();
}

const authHeader = (params) => {
    let token = getToken();
    if(token) {
        if(params){
            return {params: params, headers: {'Authorization': 'Bearer ' + token}};
        }
        return {headers: {'Authorization': 'Bearer ' + token}};
    }
    return {};
}

export const JSAuthHeader = (params) => {
    let token = getJSToken();
    if(token) {
        if(params){
            return {params: params, headers: {'Authorization': 'Bearer ' + token}};
        }
        return {headers: {'Authorization': 'Bearer ' + token}};
    }
    return {};
}

export default authHeader;