import { alertConstants } from '../constants';

export function alert(state = {}, action) {
    switch (action.type) {
    case alertConstants.SUCCESS:
        return {
        type: 'alert-success',
        message: action.message,
        name: action.name,
        };
    case alertConstants.ERROR:
        return {
        type: 'alert-danger',
        message: action.message,
        name: action.name,
        };
    case alertConstants.CLEAR:
        return {};
    default:
        return state
    }
}