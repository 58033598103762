import { openingConstants } from '../constants';

// let user = JSON.parse(localStorage.getItem('user'));
const initialState = {questions:[], decision_status: "all"};

export const uiReducer = (state = initialState, action) => {
    switch (action.type) {
    case openingConstants.MARK_QUESTION_EDIT_SUCCESS:
        let question = action.job_opening_question;
        return Object.assign({}, state, {
            questions: state.questions.concat(question)
        });
    case openingConstants.UPDATE_QUESTION_SUCCESS:
        return Object.assign({}, state, {
            questions: state.questions.filter(question=>question.job_opening_question_id !== action.job_opening_question.id)
        });
    case openingConstants.UNMARK_QUESTION_EDIT_SUCCESS:
        return Object.assign({}, state, {
            questions: state.questions.filter(question=>question.job_opening_question_id !== action.job_opening_question_id)
        });
    case openingConstants.UPDATE_QUESTION_STATE_SUCCESS:
        return Object.assign({}, state, {
            questions: state.questions.map((question)=>{
                let {job_opening_question} = action;
                if(question.job_opening_question_id === job_opening_question.job_opening_question_id){
                    let {question_title, question_type} = job_opening_question;
                    question.title = question_title
                    question.response_type = question_type
                    return Object.assign({}, question)
                }
                return question
            })
        })
    case openingConstants.CHANGE_STATUS:
        return Object.assign({}, state, {
            decision_status: action.status
        })
    default:
        return state
    }
}