import { connect } from 'react-redux';
import App from '../App';

const mapStateToProps = (state) => ({
    user: state.authentication.user,
    js_user: state.authentication.js_user,
    alert: state.alert,
})

const mapDispatchToProps = (dispatch, ownProps) =>({
    dispatch,
})

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export { connectedApp as App };