import axios from 'axios';
import {CATEGORIES_URL, INTERVIEW_TEMPLATES_URL} from '../constants/urlConstants';
import authHeader from '../helpers/authHeader';

const fetchCategories = () => {
    return axios.get(CATEGORIES_URL, authHeader())
        .then(response => {
            return response.data;
        })
}

const fetchInterviewTemplates = () => {
    return axios.get(INTERVIEW_TEMPLATES_URL, authHeader())
        .then(response => {
            return response.data;
        })
}

export const templateService = {
    fetchCategories,
    fetchInterviewTemplates,
}