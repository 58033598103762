import {openingService} from '../services';
import {alertActions, userActions} from '../actions';
import { openingConstants } from '../constants';
import {history} from '../helpers';
import {analytics, js_analytics} from '../helpers/analytics';
import {storageRef} from '../config/firebaseConfig';
import firebase from "@firebase/app"
import FileDownload from 'js-file-download';

const fetchPreScreeningQuestions = (job_application_uid) => {
    return dispatch => {
        dispatch(request())
        openingService.fetchPreScreeningQuestions(job_application_uid)
            .then(data=>{
                dispatch(success(data));
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
            })
        function request() { return { type: openingConstants.FETCH_PRESCREENING_QUESTIONS_REQUEST} }
        function success(questions) { return { type: openingConstants.FETCH_PRESCREENING_QUESTIONS_SUCCESS, questions } }
        function failure(error) { return { type: openingConstants.FETCH_PRESCREENING_QUESTIONS_FAILURE, error } }
    }
}

const createJobApplication = (job_opening_id) => {
    return dispatch => {
        dispatch(request())
        openingService.createJobApplication(job_opening_id)
            .then(data=>{
                dispatch(success(data));
                dispatch(fetchPreScreeningQuestions(data.uid))
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
            })
        function request() { return { type: openingConstants.CREATE_JOBAPPLICATION_REQUEST} }
        function success(job_application) { return { type: openingConstants.CREATE_JOBAPPLICATION_SUCCESS, job_application } }
        function failure(error) { return { type: openingConstants.CREATE_JOBAPPLICATION_FAILURE, error } }
    }
}

const updateJobApplication = (data, job_application_uid) => {
    return dispatch => {
        dispatch(request())
        openingService.updateJobApplication(data, job_application_uid)
            .then(data=>{
                dispatch(success(data));
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
            })
        function request() { return { type: openingConstants.UPDATE_JOBAPPLICATION_REQUEST} }
        function success(job_application) { return { type: openingConstants.UPDATE_JOBAPPLICATION_SUCCESS, job_application } }
        function failure(error) { return { type: openingConstants.UPDATE_JOBAPPLICATION_FAILURE, error } }
    }
}

const updateJSJobApplication = (data, job_application_uid) => {
    return dispatch => {
        dispatch(request())
        openingService.updateJSJobApplication(data, job_application_uid)
            .then(data=>{
                dispatch(success(data));
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
            })
        function request() { return { type: openingConstants.UPDATE_JS_JOBAPPLICATION_REQUEST} }
        function success(job_application) { return { type: openingConstants.UPDATE_JS_JOBAPPLICATION_SUCCESS, job_application } }
        function failure(error) { return { type: openingConstants.UPDATE_JS_JOBAPPLICATION_FAILURE, error } }
    }
}

const createResponse = (question_id, value, job_opening_id) =>{
    return dispatch => {
        dispatch(request())
        openingService.createResponse(question_id, value, job_opening_id)
            .then(data=>{
                js_analytics.track("response_added",{question_id, status:'completed', opening_id:job_opening_id,});
                dispatch(success(data));
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
            })
        function request() { return { type: openingConstants.CREATE_RESPONSE_REQUEST} }
        function success(response) { return { type: openingConstants.CREATE_RESPONSE_SUCCESS, response } }
        function failure(error) { return { type: openingConstants.CREATE_RESPONSE_FAILURE, error } }
    }
}

const createFileResponse = (question_id, file, job_opening_id) =>{
    return dispatch => {
        dispatch(request())
        openingService.createFileResponse(question_id, file, job_opening_id)
            .then(data=>{
                js_analytics.track("response_added",{question_id, status:'completed', opening_id:job_opening_id,});
                dispatch(success(data));
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
            })
        function request() { return { type: openingConstants.CREATE_RESPONSE_REQUEST} }
        function success(response) { return { type: openingConstants.CREATE_RESPONSE_SUCCESS, response } }
        function failure(error) { return { type: openingConstants.CREATE_RESPONSE_FAILURE, error } }
    }
}

const createVideoResponse = (question_id, file, job_opening_id) =>{
    return dispatch => {
        dispatch(request())
        let blob_url = URL.createObjectURL(file, {type:'video/webm'});
        let file_name = blob_url.substring(blob_url.lastIndexOf('/')+1) + '.webm';
        console.log("filename:", file_name)
        openingService.createVideoResponse(question_id, "", job_opening_id, 'in_progress', {local_video_url: blob_url, fb_video_url: file_name})
            .then(data=>{
                js_analytics.track("upload_started",{question_id, status:'in_progress', opening_id:job_opening_id,});
                dispatch(success(data));
                try {
                    const videosRef = storageRef.child('profile-videos')
                    let uploadTask = videosRef.child(file_name).put(file, { 'contentType': 'webm' });
                    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
                    function (snapshot) {
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('[Firebase Upload] progress:' + progress);
                        dispatch(update_progress(question_id, progress))
                        // store.dispatch(setSaveVideoForQuestionProgress(video.uri, progress))
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED:
                                console.log('[Firebase Upload] Upload is paused');
                                break;
                            case firebase.storage.TaskState.RUNNING:
                                console.log('[Firebase Upload] Upload is running');
                                break;
                            case firebase.storage.TaskState.SUCCESS:
                                console.log('[Firebase Upload] Upload is success');
                                break;
                            case firebase.storage.TaskState.CANCELED:
                                console.log('[Firebase Upload] Upload is canceled');
                                break;
                            case firebase.storage.TaskState.ERROR:
                                console.log('[Firebase Upload] Upload is errored');
                                // store.dispatch(setSaveVideoForQuestionFailure('Video upload error', video.uri));
                                break;
                            default:
                                break;
                        }
                    },
                    function (error) {
                        // A full list of error codes is available at
                        // https://firebase.google.com/docs/storage/web/handle-errors
                        console.log('[Firebase Upload] Error', error);
                        // store.dispatch(setSaveVideoForQuestionFailure(error.code, video.uri));
                        js_analytics.track("upload_started",{question_id, status:'upload_failed', opening_id:job_opening_id, error});
                        switch (error.code) {
                            case 'storage/unauthorized':
                                console.log('[Firebase Upload] Storage Unauthorized Error', error.code);
                                // User doesn't have permission to access the object
                                break;

                            case 'storage/canceled':
                                console.log('[Firebase Upload] Storage Canceled', error.code);
                                // User canceled the upload
                                break;

                            case 'storage/unknown':
                                console.log('[Firebase Upload] Storage Unknown error', error.code);
                                // Unknown error occurred, inspect error.serverResponse
                                break;
                            default:
                                break;
                        }
                    },
                    function(){
                        js_analytics.track("upload_started",{question_id, status:'upload_completed', opening_id:job_opening_id,});
                        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                            console.log('File available at', downloadURL);
                        });
                        openingService.createVideoResponse(question_id, "", job_opening_id, 'completed',{})
                            .then(data=>{
                                dispatch(success(data));
                            })
                    });

                } catch (err) {
                    console.log("[upload-video-worker] Error: ", err)
                    // store.dispatch(setSaveVideoForQuestionFailure(err, payload['video'].uri));
                }
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
            })
        function request() { return { type: openingConstants.CREATE_VIDEO_RESPONSE_REQUEST} }
        function success(response) { return { type: openingConstants.CREATE_VIDEO_RESPONSE_SUCCESS, response } }
        function update_progress(question_id, progress) { return { type: openingConstants.CREATE_VIDEO_RESPONSE_PROGRESS, question_id, progress } }
        function failure(error) { return { type: openingConstants.CREATE_VIDEO_RESPONSE_FAILURE, error } }
    }
}


const createJobOpening = (title, job_function, description, required_skills, location, jumpToStep) => {
    return dispatch => {
        dispatch(request());
        openingService.createJobOpening(title, job_function, description, required_skills, location)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                analytics.track('job_create', {'section': 'job_details' })
                // jumpToStep(1);
                history.push('/interview/' + data.uid + '/edit/1')
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.CREATE_JOBOPENING_REQUEST} }
        function success(job_opening) { return { type: openingConstants.CREATE_JOBOPENING_SUCCESS, job_opening } }
        function failure(error) { return { type: openingConstants.CREATE_JOBOPENING_FAILURE, error } }
    }
}

const createInterviewFromTemplate = (template_id) => {
    return dispatch => {
        dispatch(request());
        openingService.createInterviewFromTemplate(template_id)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                analytics.track('job_create_template', {'template': template_id })
                // jumpToStep(1);
                history.push('/interview/' + data.uid + '/edit/0')
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.CREATE_JOBOPENING_REQUEST} }
        function success(job_opening) { return { type: openingConstants.CREATE_JOBOPENING_SUCCESS, job_opening } }
        function failure(error) { return { type: openingConstants.CREATE_JOBOPENING_FAILURE, error } }
    }
}



const updateJobOpening = (job_opening_id, opening_fields, jumpToNextStep) => {
    return dispatch => {
        dispatch(request());
        openingService.updateJobOpening(job_opening_id, opening_fields)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                analytics.track('job_update',{"job_opening_id": job_opening_id})
                if(jumpToNextStep){
                    history.push('/interview/' + data.uid + '/edit/1')
                }
                if('status' in opening_fields && opening_fields['status']==='archived'){
                    dispatch(updateDecisionStatus('archived'))
                }
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.UPDATE_JOBOPENING_REQUEST} }
        function success(job_opening) { return { type: openingConstants.UPDATE_JOBOPENING_SUCCESS, job_opening } }
        function failure(error) { return { type: openingConstants.UPDATE_JOBOPENING_FAILURE, error } }
    }
}

const clearJobOpening = () => {
    return dispatch => {
        dispatch(success());
        function success() { return { type: openingConstants.CLEAR_JOBOPENING_SUCCESS } }
    }
}

const addJobOpeningReviewers = (job_opening_id, user_id) => {
    return dispatch => {
        dispatch(request());
        openingService.addJobOpeningReviewers(job_opening_id, user_id)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.ADD_JOBOPENING_REVIEWERS_REQUEST} }
        function success(reviewer) { return { type: openingConstants.ADD_JOBOPENING_REVIEWERS_SUCCESS, reviewer } }
        function failure(error) { return { type: openingConstants.ADD_JOBOPENING_REVIEWERS_FAILURE, error } }
    }
}



const updateJobOpeningStatus = (job_opening_id, fields, status) => {
    return dispatch => {
        dispatch(request());
        openingService.updateJobOpening(job_opening_id, {...fields, status: status, reset_interviews: true})
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                analytics.track('update_job_opening_status', {'status': status })
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.UPDATE_JOBOPENING_STATUS_REQUEST} }
        function success(job_opening) { return { type: openingConstants.UPDATE_JOBOPENING_STATUS_SUCCESS, job_opening } }
        function failure(error) { return { type: openingConstants.UPDATE_JOBOPENING_STATUS_FAILURE, error } }
    }
}

const createQuestion = (question_title, question_type, question_description, job_opening_id, question_range, question_choices, media_type, answer_key, positive_score, negative_score, is_mandatory) => {
    return dispatch => {
        dispatch(request());
        openingService.createJobOpeningQuestion(question_title, question_type, question_description, job_opening_id, question_range, question_choices, media_type, answer_key, positive_score, negative_score, is_mandatory)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                analytics.track('question_create', {'job_opening_id': job_opening_id })
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.CREATE_JOBOPENING_QUESTION_REQUEST} }
        function success(job_opening_question) { return { type: openingConstants.CREATE_JOBOPENING_QUESTION_SUCCESS, job_opening_question } }
        function failure(error) { return { type: openingConstants.CREATE_JOBOPENING_QUESTION_FAILURE, error } }
    }
}

const sendInvites = (data) => {
    return dispatch => {
        dispatch(request());
        openingService.sendInvites(data)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.success('Successfully uploaded, Invites will be sent to the candidates shortly.', 'SEND_INVITES'));
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.SEND_INVITES_REQUEST} }
        function success(job_opening_question) { return { type: openingConstants.SEND_INVITES_SUCCESS, job_opening_question } }
        function failure(error) { return { type: openingConstants.SEND_INVITES_FAILURE, error } }
    }
}

const orderQuestion = (job_opening_question_id, job_opening_id, oldIndex, newIndex) => {
    return dispatch => {
        dispatch(request());
        openingService.orderQuestion(job_opening_question_id, job_opening_id, oldIndex, newIndex)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                analytics.track('question_order', {'job_opening_id': job_opening_id })
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.ORDER_QUESTION_REQUEST} }
        function success(job_opening) { return { type: openingConstants.ORDER_QUESTION_SUCCESS, job_opening } }
        function failure(error) { return { type: openingConstants.ORDER_QUESTION_FAILURE, error } }
    }
}

const updateJobDescription = (description, opening_id, jumpToStep) => {
    return dispatch => {
        dispatch(request());
        openingService.updateJobDescription(description, opening_id)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                analytics.track('job_create', {'section': 'job_description' })
                jumpToStep(2);
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.UPDATE_JOB_DESCRIPTION_REQUEST} }
        function success(job_opening) { return { type: openingConstants.UPDATE_JOB_DESCRIPTION_SUCCESS, job_opening } }
        function failure(error) { return { type: openingConstants.UPDATE_JOB_DESCRIPTION_FAILURE, error } }
    }
}

const fetchJobOpening = (job_uid) => {
    return dispatch => {
        dispatch(request());
        openingService.fetchJobOpening(job_uid)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.FETCH_JOBOPENING_REQUEST} }
        function success(job_opening) { return { type: openingConstants.FETCH_JOBOPENING_SUCCESS, job_opening } }
        function failure(error) { return { type: openingConstants.FETCH_JOBOPENING_FAILURE, error } }
    }
}

const fetchGenericInterview = (job_uid) => {
    return dispatch => {
        dispatch(request());
        openingService.fetchGenericInterview(job_uid)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.FETCH_JOBOPENING_REQUEST} }
        function success(job_opening) { return { type: openingConstants.FETCH_JOBOPENING_SUCCESS, job_opening } }
        function failure(error) { return { type: openingConstants.FETCH_JOBOPENING_FAILURE, error } }
    }
}

const updateFilter = (filter_id, value) => {
    return dispatch => {
        let filter_obj = {filter_id, value}
        dispatch(success(filter_obj))
        function success(filter) {return { type: openingConstants.UPDATE_FILTER, filter } }
    }
}

const updateDecisionStatus = (status) => {
    return dispatch => {
        dispatch(success(status))
        function success(status) {return { type: openingConstants.CHANGE_STATUS, status } }
    }
}

const clearApplication = () => {
    return dispatch => {
        dispatch(success())
        function success() {return { type: openingConstants.CLEAR_APPLICATION } }
    }
}

const fetchJobCandidates = (job_uid, filters, decision_status) => {
    return dispatch => {
        dispatch(request());
        openingService.fetchJobCandidates(job_uid, filters, decision_status)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.FETCH_JOBCANDIDATES_REQUEST} }
        function success(applications) { return { type: openingConstants.FETCH_JOBCANDIDATES_SUCCESS, applications } }
        function failure(error) { return { type: openingConstants.FETCH_JOBCANDIDATES_FAILURE, error } }
    }
}

const fetchMoreJobCandidates = (next_url) => {
    return dispatch => {
        dispatch(request());
        openingService.fetchMoreJobCandidates(next_url)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.FETCH_MORE_JOBCANDIDATES_REQUEST} }
        function success(applications) { return { type: openingConstants.FETCH_MORE_JOBCANDIDATES_SUCCESS, applications } }
        function failure(error) { return { type: openingConstants.FETCH_MORE_JOBCANDIDATES_FAILURE, error } }
    }
}

const fetchJobCandidatesList = (job_uid) => {
    return dispatch => {
        dispatch(request());
        openingService.fetchJobCandidatesList(job_uid)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.FETCH_JOBCANDIDATES_LIST_REQUEST} }
        function success(data) { return { type: openingConstants.FETCH_JOBCANDIDATES_LIST_SUCCESS, data } }
        function failure(error) { return { type: openingConstants.FETCH_JOBCANDIDATES_LIST_FAILURE, error } }
    }
}

const fetchJobCandidate = (job_uid, profile_uid) => {
    return dispatch => {
        dispatch(request());
        openingService.fetchJobCandidate(job_uid, profile_uid)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.FETCH_JOBCANDIDATE_REQUEST} }
        function success(application) { return { type: openingConstants.FETCH_JOBCANDIDATE_SUCCESS, application } }
        function failure(error) { return { type: openingConstants.FETCH_JOBCANDIDATE_FAILURE, error } }
    }
}

const fetchPublicJobCandidate = (job_uid, profile_uid) => {
    return dispatch => {
        dispatch(request());
        openingService.fetchPublicJobCandidate(job_uid, profile_uid)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.FETCH_JOBCANDIDATE_REQUEST} }
        function success(application) { return { type: openingConstants.FETCH_JOBCANDIDATE_SUCCESS, application } }
        function failure(error) { return { type: openingConstants.FETCH_JOBCANDIDATE_FAILURE, error } }
    }
}

const fetchJobOpenings = () => {
    return dispatch => {
        dispatch(request());
        openingService.fetchJobOpenings()
            .then(data=>{
                dispatch(success(data))
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.FETCH_JOBOPENINGS_REQUEST} }
        function success(job_openings) { return { type: openingConstants.FETCH_JOBOPENINGS_SUCCESS, job_openings } }
        function failure(error) { return { type: openingConstants.FETCH_JOBOPENINGS_FAILURE, error } }
    }
}

const fetchJobOpeningsById = (job_opening_uid) => {
    return dispatch => {
        dispatch(request());
        openingService.fetchJobOpeningsById(job_opening_uid)
            .then(data=>{
                dispatch(success(data))
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.FETCH_JOBOPENINGS_BY_ID_REQUEST} }
        function success(job_opening) { return { type: openingConstants.FETCH_JOBOPENINGS_BY_ID_SUCCESS, job_opening } }
        function failure(error) { return { type: openingConstants.FETCH_JOBOPENINGS_BY_ID_FAILURE, error } }
    }
}

const downloadInterviewSessionsCSV = (job_opening) => {
    return dispatch => {
        openingService.downloadInterviewSessionsCSV(job_opening.uid).then(data => {
            let format = (date) => {
                var day = date.getDate();
                var monthIndex = date.getMonth();
                var year = date.getFullYear();
                return year + "_" + monthIndex + "_" + day;
            }
            FileDownload(data.data, job_opening.title.split(" ").join('_') + "_" + format(new Date()) + '.csv', "text/csv");
        });
    }
}

const fetchJobOpeningFilters = (job_uid) => {
    return dispatch => {
        dispatch(request());
        openingService.fetchJobOpeningFilters(job_uid)
            .then(data=>{
                dispatch(success(data))
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.FETCH_JOBOPENING_FILTERS_REQUEST} }
        function success(filters) { return { type: openingConstants.FETCH_JOBOPENING_FILTERS_SUCCESS, filters } }
        function failure(error) { return { type: openingConstants.FETCH_JOBOPENING_FILTERS_FAILURE, error } }
    }
}

const fetchJobFunctions = () => {
    return dispatch => {
        dispatch(request());
        openingService.fetchJobFunctions()
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.FETCH_JOBFUNCTIONS_REQUEST} }
        function success(job_functions) { return { type: openingConstants.FETCH_JOBFUNCTIONS_SUCCESS, job_functions } }
        function failure(error) { return { type: openingConstants.FETCH_JOBFUNCTIONS_FAILURE, error } }
    }
}

const markQuestionEdit = (job_opening_question) => {
    return dispatch => {
        dispatch(request());
        try{
            dispatch(success(job_opening_question));
        }catch{
            dispatch(failure('Could not mark the question for editing'));
        }
        function request() { return { type: openingConstants.MARK_QUESTION_EDIT_REQUEST} }
        function success(job_opening_question) { return { type: openingConstants.MARK_QUESTION_EDIT_SUCCESS, job_opening_question } }
        function failure(error) { return { type: openingConstants.MARK_QUESTION_EDIT_FAILURE, error } }
    }
}

const unMarkQuestionEdit = (job_opening_question_id) => {
    return dispatch => {
        dispatch(request());
        try{
            dispatch(success(job_opening_question_id));
        }catch{
            dispatch(failure('Could not unmark the question for editing'));
        }
        function request() { return { type: openingConstants.UNMARK_QUESTION_EDIT_REQUEST} }
        function success(job_opening_question_id) { return { type: openingConstants.UNMARK_QUESTION_EDIT_SUCCESS, job_opening_question_id } }
        function failure(error) { return { type: openingConstants.UNMARK_QUESTION_EDIT_FAILURE, error } }
    }
}

const updateQuestion = (job_opening_question_id, question_title, question_type, question_description, question_range, question_choices, media_type, answer_key, positive_score, negative_score, is_mandatory) => {
    return dispatch => {
        dispatch(request());
        openingService.updateQuestion(job_opening_question_id, question_title, question_type, question_description, question_range, question_choices, media_type, answer_key, positive_score, negative_score, is_mandatory)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                analytics.track('update_question', {'job_opening_question_id': job_opening_question_id })
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.UPDATE_QUESTION_REQUEST} }
        function success(job_opening_question) { return { type: openingConstants.UPDATE_QUESTION_SUCCESS, job_opening_question } }
        function failure(error) { return { type: openingConstants.UPDATE_QUESTION_FAILURE, error } }
    }
}

const deleteQuestion = (job_opening_question_id) => {
    return dispatch => {
        dispatch(request());
        openingService.deleteQuestion(job_opening_question_id)
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
                analytics.track('delete_question', {'job_opening_question_id': job_opening_question_id })
                return true
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: openingConstants.DELETE_QUESTION_REQUEST} }
        function success(job_opening) { return { type: openingConstants.DELETE_QUESTION_SUCCESS, job_opening} }
        function failure(error) { return { type: openingConstants.DELETE_QUESTION_FAILURE, error } }
    }
}

const updateQuestionState = (job_opening_question_id, question_title, question_type) => {
    return dispatch => {
        dispatch(request());
        dispatch(success({job_opening_question_id, question_title, question_type}));

        function request() { return { type: openingConstants.UPDATE_QUESTION_STATE_REQUEST} }
        function success(job_opening_question) { return { type: openingConstants.UPDATE_QUESTION_STATE_SUCCESS, job_opening_question } }
    }
}

export const openingActions = {
    createJobApplication,
    updateJobApplication,
    updateJSJobApplication,
    createResponse,
    createFileResponse,
    createVideoResponse,
    fetchGenericInterview,
    fetchJobOpening,
    fetchJobOpeningFilters,
    fetchJobOpenings,
    fetchJobCandidates,
    fetchJobCandidate,
    fetchPublicJobCandidate,
    createJobOpening,
    updateJobOpening,
    createQuestion,
    updateJobDescription,
    fetchJobFunctions,
    fetchJobCandidatesList,
    fetchMoreJobCandidates,
    updateFilter,
    markQuestionEdit,
    unMarkQuestionEdit,
    updateQuestion,
    updateQuestionState,
    deleteQuestion,
    orderQuestion,
    fetchJobOpeningsById,
    updateJobOpeningStatus,
    sendInvites,
    addJobOpeningReviewers,
    clearJobOpening,
    updateDecisionStatus,
    clearApplication,
    downloadInterviewSessionsCSV,
    createInterviewFromTemplate,
}
