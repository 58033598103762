import axios from 'axios';
import {ORGANIZATION_URL} from '../constants/urlConstants';
import authHeader from '../helpers/authHeader';

const updateOrganization = (website, careers_page, logo) => {
    let headers = authHeader();
    headers['Content-Type']='multipart/form-data';
    let formData = new FormData();
    formData.append('logo',logo);
    formData.append('website', website);
    formData.append('careers_page', careers_page);
    return axios.patch(ORGANIZATION_URL + '1/', formData, headers)
        .then(response=>{
            return response.data;
        })
}
export const organizationService = {
    updateOrganization
}