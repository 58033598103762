export const scoreConstants = {
    CREATE_SCORE_REQUEST: 'CREATE_SCORE_REQUEST',
    CREATE_SCORE_SUCCESS: 'CREATE_SCORE_SUCCESS',
    CREATE_SCORE_FAILURE: 'CREATE_SCORE_FAILURE',
    FETCH_SCORES_REQUEST: 'FETCH_SCORES_REQUEST',
    FETCH_SCORES_SUCCESS: 'FETCH_SCORES_SUCCESS',
    FETCH_SCORES_FAILURE: 'FETCH_SCORES_FAILURE',
    FETCH_APPLICATION_SCORES_REQUEST: 'FETCH_APPLICATION_SCORES_REQUEST',
    FETCH_APPLICATION_SCORES_SUCCESS: 'FETCH_APPLICATION_SCORES_SUCCESS',
    FETCH_APPLICATION_SCORES_FAILURE: 'FETCH_APPLICATION_SCORES_FAILURE',
    CREATE_APPLICATION_SCORE_REQUEST: 'CREATE_APPLICATION_SCORE_REQUEST',
    CREATE_APPLICATION_SCORE_SUCCESS: 'CREATE_APPLICATION_SCORE_SUCCESS',
    CREATE_APPLICATION_SCORE_FAILURE: 'CREATE_APPLICATION_SCORE_FAILURE',
}