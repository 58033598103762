import {alertConstants} from '../constants';

const success = (message, name=null) => ({type: alertConstants.SUCCESS, message, name});
const error = (message, name=null) => ({type: alertConstants.ERROR, message, name});
const clear = () => ({type: alertConstants.CLEAR});

export const alertActions = {
    success,
    error,
    clear
}