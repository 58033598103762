const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://interviewpass-220306.appspot.com/' : 'http://localhost:8000/';
// const BASE_URL = 'https://interviewpass-220306.appspot.com/';
const MANAGE_API_BASE_URL = BASE_URL + 'manage-api/v1/';
const TEST_CENTER_API_BASE_URL = BASE_URL + 'test-center/v1/';

export const JS_SIGNUP_URL = BASE_URL + 'jsauth/registration/'
export const SIGNIN_URL= BASE_URL + 'auth/'
export const AUTH_VERIFY_URL = BASE_URL + 'auth-verify/'

export const UPLOAD_URL = MANAGE_API_BASE_URL + 'pub-upload/'

export const SIGNUP_URL= MANAGE_API_BASE_URL + 'companyauth/registration/'
export const ADD_USER_URL= MANAGE_API_BASE_URL + 'companyauth/add-user/'
export const PROFILE_URL = MANAGE_API_BASE_URL + 'job-seeker-profile/'

export const JOB_OPENING_URL = MANAGE_API_BASE_URL + 'job/'
export const JOB_OPENING_FILTERS_URL = MANAGE_API_BASE_URL + 'custom-fields/'
export const JOB_OPENINGS_URL = MANAGE_API_BASE_URL + 'job-openings/'
export const JOB_CANDIDATES_URL = MANAGE_API_BASE_URL + 'job-candidates/'
export const PUBLIC_JOB_CANDIDATES_URL = MANAGE_API_BASE_URL + 'public-job-candidates/'
export const JOB_OPENING_QUESTIONS_URL = MANAGE_API_BASE_URL + 'job-questions/'
export const JOB_OPENING_REVIEWERS_URL = MANAGE_API_BASE_URL + 'job-reviewers/'
export const JOB_FUNCTIONS_URL = MANAGE_API_BASE_URL + 'job-functions/'
export const JOB_APPLICATIONS_URL = MANAGE_API_BASE_URL + 'job-applications/'
export const RESPONSE_URL = MANAGE_API_BASE_URL + 'responses/'
export const INTERACTIONS_URL = MANAGE_API_BASE_URL + 'interactions/'

export const ORGANIZATION_URL = MANAGE_API_BASE_URL + 'organizations/'
export const NOTES_URL = MANAGE_API_BASE_URL + 'notes/'
export const STARS_URL = MANAGE_API_BASE_URL + 'stars/'
export const REVIEWS_URL = MANAGE_API_BASE_URL + 'reviews/'
export const USERS_URL = MANAGE_API_BASE_URL + 'users/'
export const VERIFY_EMAIL_URL = MANAGE_API_BASE_URL + 'verify-email/'
export const SET_PASSWORD_URL = MANAGE_API_BASE_URL + 'set-password/'
export const RESET_PASSWORD_URL = BASE_URL + 'rest-auth/password/reset/'
export const RESET_PASSWORD_CONFIRM_URL = BASE_URL + 'rest-auth/password/reset/confirm/'
export const JOB_CANDIDATES_LIST_URL = MANAGE_API_BASE_URL + 'candidate-list/'
export const INTERVIEW_SESSIONS_DOWNLOAD_URL = MANAGE_API_BASE_URL + 'applicant-list/'
export const INTERVIEW_INVITES_URL = MANAGE_API_BASE_URL + 'interview-invitees/'
export const CATEGORIES_URL = MANAGE_API_BASE_URL + 'categories/'
export const INTERVIEW_TEMPLATES_URL = MANAGE_API_BASE_URL + 'interview-templates/'
export const SCORE_URL = MANAGE_API_BASE_URL + 'score/'
export const SUBSCRIPTIONS_URL = MANAGE_API_BASE_URL + 'subscriptions/'
export const ASSESSMENTS_URL = MANAGE_API_BASE_URL + 'assessments/'
export const ASSESSMENT_TEMPLATES_URL = MANAGE_API_BASE_URL + 'assessment-templates/'
export const ASSESSMENT_QUESTIONS_URL = MANAGE_API_BASE_URL + 'assessment-questions/'
export const ASSESSMENT_REVIEWERS_URL = MANAGE_API_BASE_URL + 'assessment-reviewers/'
export const ASSESSMENT_SUBMISSIONS_URL = MANAGE_API_BASE_URL + 'assessment-submissions/'
export const ASSESSMENT_CANDIDATES_URL = MANAGE_API_BASE_URL + 'assessment-candidates/'
export const ASSESSMENT_INVITEES_URL = MANAGE_API_BASE_URL + 'assessment-invitees/'
export const ASSESSMENT_SCORE_REPORT_URL = MANAGE_API_BASE_URL + 'assessment-score/'
export const ASSESSMENT_RUBRICS_REPORT_URL = MANAGE_API_BASE_URL + 'assessment-rubrics/'
export const ASSESSMENT_SUBMISSIONS_REPORT_URL = MANAGE_API_BASE_URL + 'assessment-submissions-report/'

export const JS_ASSESSMENTS_URL = TEST_CENTER_API_BASE_URL + 'assessments/'

export const GENERIC_INTERVIEW_URL = TEST_CENTER_API_BASE_URL + 'interview/'
