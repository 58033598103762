import { subscriptionConstants } from '../constants';

// let user = JSON.parse(localStorage.getItem('user'));
const initialState = {subscriptions:[]};

export const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
    case subscriptionConstants.FETCH_SUBSCRIPTIONS_SUCCESS:
        return Object.assign({}, state, {
            subscriptions: action.subscriptions
        });
    default:
        return state
    }
}