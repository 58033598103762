import { userConstants } from '../constants';

// let user = JSON.parse(localStorage.getItem('user'));
const initialState = {is_fetching:false};

export const job_seeker = (state = initialState, action) => {
    switch (action.type) {
    case userConstants.FETCH_JOBSEEKER_REQUEST:
        return {
            is_fetching:true
        };
    case userConstants.FETCH_JOBSEEKER_SUCCESS:
        return {
            is_fetching: false,
            data: action.job_seeker
        };
    case userConstants.FETCH_JOBSEEKER_FAILURE:
        return {};
    default:
        return state
    }
}