export const userConstants = {
    ANON_USERS_SIGNIN_REQUEST: 'ANON_USERS_SIGNIN_REQUEST',
    ANON_USERS_SIGNIN_SUCCESS: 'ANON_USERS_SIGNIN_SUCCESS',
    ANON_USERS_SIGNIN_FAILURE: 'ANON_USERS_SIGNIN_FAILURE',
    JOBSEEKER_SIGNIN_REQUEST: 'JOBSEEKER_SIGNIN_REQUEST',
    JOBSEEKER_SIGNIN_SUCCESS: 'JOBSEEKER_SIGNIN_SUCCESS',
    JOBSEEKER_SIGNIN_FAILURE: 'JOBSEEKER_SIGNIN_FAILURE',
    USERS_SIGNIN_REQUEST: 'USERS_SIGNIN_REQUEST',
    USERS_SIGNIN_SUCCESS: 'USERS_SIGNIN_SUCCESS',
    USERS_SIGNIN_FAILURE: 'USERS_SIGNIN_FAILURE',
    USERS_SIGNUP_REQUEST: 'USERS_SIGNUP_REQUEST',
    USERS_SIGNUP_SUCCESS: 'USERS_SIGNUP_SUCCESS',
    USERS_SIGNUP_FAILURE: 'USERS_SIGNUP_FAILURE',
    USERS_SIGNOUT: 'USERS_SIGNOUT',
    USERS_JS_SIGNOUT: 'USERS_JS_SIGNOUT',
    FETCH_JOBSEEKER_REQUEST: 'FETCH_JOBSEEKER_REQUEST',
    FETCH_JOBSEEKER_SUCCESS: 'FETCH_JOBSEEKER_SUCCESS',
    FETCH_JOBSEEKER_FAILURE: 'FETCH_JOBSEEKER_FAILURE',
    FETCH_USERS_REQUEST: 'FETCH_USERS_REQUEST',
    FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
    FETCH_USERS_FAILURE: 'FETCH_USERS_FAILURE',
    VERIFY_AUTH_TOKEN_REQUEST: 'VERIFY_AUTH_TOKEN_REQUEST',
    VERIFY_AUTH_TOKEN_SUCCESS: 'VERIFY_AUTH_TOKEN_SUCCESS',
    VERIFY_AUTH_TOKEN_FAILURE: 'VERIFY_AUTH_TOKEN_FAILURE',
    VERIFY_JS_AUTH_TOKEN_REQUEST: 'VERIFY_JS_AUTH_TOKEN_REQUEST',
    VERIFY_JS_AUTH_TOKEN_SUCCESS: 'VERIFY_JS_AUTH_TOKEN_SUCCESS',
    VERIFY_JS_AUTH_TOKEN_FAILURE: 'VERIFY_JS_AUTH_TOKEN_FAILURE',
    ADD_USER_REQUEST: 'ADD_USER_REQUEST',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAILURE: 'ADD_USER_FAILURE',
    UPDATE_JS_USER_REQUEST: 'UPDATE_JS_USER_REQUEST',
    UPDATE_JS_USER_SUCCESS: 'UPDATE_JS_USER_SUCCESS',
    UPDATE_JS_USER_FAILURE: 'UPDATE_JS_USER_FAILURE',
    VERIFY_EMAIL_REQUEST: 'VERIFY_EMAIL_REQUEST',
    VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
    VERIFY_EMAIL_FAILURE: 'VERIFY_EMAIL_FAILURE',
    SET_PASSWORD_REQUEST: 'SET_PASSWORD_REQUEST',
    SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
    SET_PASSWORD_FAILURE: 'SET_PASSWORD_FAILURE',
    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
    RESET_PASSWORD_CONFIRM_REQUEST: 'RESET_PASSWORD_CONFIRM_REQUEST',
    RESET_PASSWORD_CONFIRM_SUCCESS: 'RESET_PASSWORD_CONFIRM_SUCCESS',
    RESET_PASSWORD_CONFIRM_FAILURE: 'RESET_PASSWORD_CONFIRM_FAILURE',
}