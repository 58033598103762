import React, { Component, Fragment } from 'react'
import {Navbar, Nav, NavDropdown, Badge} from 'react-bootstrap';
import logo from '../../images/logo_transparent.png';
import {history} from '../../helpers'
export default class NavBar extends Component{
    renderUnVerifiedBar(){
        const { current_user } = this.props;
        if(current_user && current_user.hasOwnProperty('is_verified') && !current_user.is_verified){
        return(<div className={'alert alert-warning text-center mb-0'}><i className="material-icons-outlined">info</i>Verify your email address by clicking on the link sent to your inbox. You cannot publish an interview unless your account is verified.</div>)
        }
    }

    getActiveKey(){
        let url = window.location.href;
        if(url.indexOf('assessments') !== -1){
            return "assessments"
        }else if(url.indexOf('interviews') !== -1){
            return "interviews"
        }
        return ''
    }
    render(){
        // return(
        //     <Fragment />
        // )
        let user = this.props.current_user;
        return(
            <Fragment>
                {this.renderUnVerifiedBar()}
                <Navbar bg="dark" variant="dark" expand="lg" className="pl-3 pr-3">
                    <Navbar.Brand>
                        <Nav.Link onClick={()=>{history.push('/interviews')}} className="text-decoration-none">
                            <img alt="logo" src={logo} style={{width:36, marginTop: -7}} />
                            <span className="txt-white text-decoration-none">InterviewPass</span>
                        </Nav.Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto" activeKey={this.getActiveKey()}>
                            <Nav.Link eventKey="interviews" onClick={()=>{history.push('/interviews/');}}>Interviews</Nav.Link>
                            <Nav.Link eventKey="assessments" onClick={()=>{history.push('/assessments/');}}>Assessments<Badge variant="warning" className="ml-2">Beta</Badge></Nav.Link>
                            <NavDropdown title={<span><span>{user && user.first_name}</span></span>} nocaret="true" id="collasible-nav-dropdown">
                                <NavDropdown.Item onClick={()=>{history.push('/settings/');}}>Settings</NavDropdown.Item>
                                <NavDropdown.Item onClick={()=>{this.props.signOut()}}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Fragment>
        )
    }
}