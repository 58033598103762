import axios from 'axios';
import {REVIEWS_URL} from '../constants/urlConstants';
import authHeader from '../helpers/authHeader';

const fetchReviewsForApplication = (job_application_uid) => {
    return axios.get(REVIEWS_URL, authHeader({job_application_uid}))
        .then(response => {
            return response.data;
        })
}

const createReviewForApplication = (status,recommendation, note, job_application_uid) => {
    return axios.post(REVIEWS_URL, {status,recommendation, note, job_application:job_application_uid}, authHeader())
            .then(response=>{
                return response.data
            })
}
const fetchReviewsForAssessmentSubmission = (assessment_submission_uid) => {
    return axios.get(REVIEWS_URL, authHeader({assessment_submission_uid}))
        .then(response => {
            return response.data;
        })
}

const createReviewForAssessmentSubmission = (recommendation, note, assessment_submission_uid) => {
    return axios.post(REVIEWS_URL, {recommendation, note, assessment_submission:assessment_submission_uid}, authHeader())
            .then(response=>{
                return response.data
            })
}

export const reviewService = {
    fetchReviewsForApplication,
    createReviewForApplication,
    fetchReviewsForAssessmentSubmission,
    createReviewForAssessmentSubmission,
}