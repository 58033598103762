import mixpanel from "mixpanel-browser";
let env_check = process.env.NODE_ENV === "production";

if (env_check) {
    mixpanel.init("2b649ece1dad7046356c4980fa20c09b");
    mixpanel.init("9ec64240cf865bc5ce81be3a608b8687",{},"interview_app");
}

let actions = {
    identify: id => {
        if (env_check) mixpanel.identify(id);
    },
    alias: id => {
        if (env_check) mixpanel.alias(id);
    },
    track: (name, props) => {
        if (env_check) mixpanel.track(name, props);
    },
    people: {
        set: props => {
            if (env_check) mixpanel.people.set(props);
        }
    }
};

let js_actions = {
    identify: id => {
        if (env_check) mixpanel.interview_app.identify(id);
    },
    alias: id => {
        if (env_check) mixpanel.interview_app.alias(id);
    },
    track: (name, props) => {
        if (env_check) mixpanel.interview_app.track(name, props);
    },
    people: {
        set: props => {
            if (env_check) mixpanel.interview_app.people.set(props);
        }
    }
};

export let analytics = actions;
export let js_analytics = js_actions;
