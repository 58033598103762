export const assessmentConstants = {
    CREATE_ASSESSMENT_REQUEST: 'CREATE_ASSESSMENT_REQUEST',
    CREATE_ASSESSMENT_SUCCESS: 'CREATE_ASSESSMENT_SUCCESS',
    CREATE_ASSESSMENT_FAILURE: 'CREATE_ASSESSMENT_FAILURE',

    CLONE_ASSESSMENT_REQUEST: 'CLONE_ASSESSMENT_REQUEST',
    CLONE_ASSESSMENT_SUCCESS: 'CLONE_ASSESSMENT_SUCCESS',
    CLONE_ASSESSMENT_FAILURE: 'CLONE_ASSESSMENT_FAILURE',

    UPDATE_ASSESSMENT_REQUEST: 'UPDATE_ASSESSMENT_REQUEST',
    UPDATE_ASSESSMENT_SUCCESS: 'UPDATE_ASSESSMENT_SUCCESS',
    UPDATE_ASSESSMENT_FAILURE: 'UPDATE_ASSESSMENT_FAILURE',

    ADD_ASSESSMENT_REVIEWERS_REQUEST: 'ADD_ASSESSMENT_REVIEWERS_REQUEST',
    ADD_ASSESSMENT_REVIEWERS_SUCCESS: 'ADD_ASSESSMENT_REVIEWERS_SUCCESS',
    ADD_ASSESSMENT_REVIEWERS_FAILURE: 'ADD_ASSESSMENT_REVIEWERS_FAILURE',

    UPDATE_ASSESSMENT_STATUS_REQUEST: 'UPDATE_ASSESSMENT_STATUS_REQUEST',
    UPDATE_ASSESSMENT_STATUS_SUCCESS: 'UPDATE_ASSESSMENT_STATUS_SUCCESS',
    UPDATE_ASSESSMENT_STATUS_FAILURE: 'UPDATE_ASSESSMENT_STATUS_FAILURE',

    CREATE_ASSESSMENT_QUESTION_REQUEST: 'CREATE_ASSESSMENT_QUESTION_REQUEST',
    CREATE_ASSESSMENT_QUESTION_SUCCESS: 'CREATE_ASSESSMENT_QUESTION_SUCCESS',
    CREATE_ASSESSMENT_QUESTION_FAILURE: 'CREATE_ASSESSMENT_QUESTION_FAILURE',

    CREATE_ASSESSMENT_SUBMISSION_REQUEST: 'CREATE_ASSESSMENT_SUBMISSION_REQUEST',
    CREATE_ASSESSMENT_SUBMISSION_SUCCESS: 'CREATE_ASSESSMENT_SUBMISSION_SUCCESS',
    CREATE_ASSESSMENT_SUBMISSION_FAILURE: 'CREATE_ASSESSMENT_SUBMISSION_FAILURE',

    UPDATE_ASSESSMENT_SUBMISSION_REQUEST: 'UPDATE_ASSESSMENT_SUBMISSION_REQUEST',
    UPDATE_ASSESSMENT_SUBMISSION_SUCCESS: 'UPDATE_ASSESSMENT_SUBMISSION_SUCCESS',
    UPDATE_ASSESSMENT_SUBMISSION_FAILURE: 'UPDATE_ASSESSMENT_SUBMISSION_FAILURE',

    FETCH_ASSESSMENTS_REQUEST: 'FETCH_ASSESSMENTS_REQUEST',
    FETCH_ASSESSMENTS_SUCCESS: 'FETCH_ASSESSMENTS_SUCCESS',
    FETCH_ASSESSMENTS_FAILURE: 'FETCH_ASSESSMENTS_FAILURE',

    FETCH_ASSESSMENT_REQUEST: 'FETCH_ASSESSMENT_REQUEST',
    FETCH_ASSESSMENT_SUCCESS: 'FETCH_ASSESSMENT_SUCCESS',
    FETCH_ASSESSMENT_FAILURE: 'FETCH_ASSESSMENT_FAILURE',

    FETCH_ASSESSMENT_TEMPLATES_REQUEST: 'FETCH_ASSESSMENT_TEMPLATES_REQUEST',
    FETCH_ASSESSMENT_TEMPLATES_SUCCESS: 'FETCH_ASSESSMENT_TEMPLATES_SUCCESS',
    FETCH_ASSESSMENT_TEMPLATES_FAILURE: 'FETCH_ASSESSMENT_TEMPLATES_FAILURE',

    FETCH_ASSESSMENT_SUBMISSIONS_REQUEST: 'FETCH_ASSESSMENT_SUBMISSIONS_REQUEST',
    FETCH_ASSESSMENT_SUBMISSIONS_SUCCESS: 'FETCH_ASSESSMENT_SUBMISSIONS_SUCCESS',
    FETCH_ASSESSMENT_SUBMISSIONS_FAILURE: 'FETCH_ASSESSMENT_SUBMISSIONS_FAILURE',

    FETCH_ASSESSMENT_CANDIDATE_REQUEST: 'FETCH_ASSESSMENT_CANDIDATES_REQUEST',
    FETCH_ASSESSMENT_CANDIDATE_SUCCESS: 'FETCH_ASSESSMENT_CANDIDATES_SUCCESS',
    FETCH_ASSESSMENT_CANDIDATE_FAILURE: 'FETCH_ASSESSMENT_CANDIDATES_FAILURE',

    CLEAR_ASSESSMENT_CANDIDATE_SUCCESS: 'CLEAR_ASSESSMENT_CANDIDATE_SUCCESS',

    FETCH_ASSESSMENT_CANDIDATES_REQUEST: 'FETCH_ASSESSMENT_CANDIDATE_REQUEST',
    FETCH_ASSESSMENT_CANDIDATES_SUCCESS: 'FETCH_ASSESSMENT_CANDIDATE_SUCCESS',
    FETCH_ASSESSMENT_CANDIDATES_FAILURE: 'FETCH_ASSESSMENT_CANDIDATE_FAILURE',

    FETCH_MORE_ASSESSMENT_CANDIDATES_REQUEST: 'FETCH_MORE_ASSESSMENT_CANDIDATES_REQUEST',
    FETCH_MORE_ASSESSMENT_CANDIDATES_SUCCESS: 'FETCH_MORE_ASSESSMENT_CANDIDATES_SUCCESS',
    FETCH_MORE_ASSESSMENT_CANDIDATES_FAILURE: 'FETCH_MORE_ASSESSMENT_CANDIDATES_FAILURE',

    UPDATE_ASSESSMENT_QUESTION_REQUEST: 'UPDATE_ASSESSMENT_QUESTION_REQUEST',
    UPDATE_ASSESSMENT_QUESTION_FAILURE: 'UPDATE_ASSESSMENT_QUESTION_FAILURE',
    UPDATE_ASSESSMENT_QUESTION_SUCCESS: 'UPDATE_ASSESSMENT_QUESTION_SUCCESS',

    DELETE_ASSESSMENT_QUESTION_REQUEST: 'DELETE_ASSESSMENT_QUESTION_REQUEST',
    DELETE_ASSESSMENT_QUESTION_SUCCESS: 'DELETE_ASSESSMENT_QUESTION_SUCCESS',
    DELETE_ASSESSMENT_QUESTION_FAILURE: 'DELETE_ASSESSMENT_QUESTION_FAILURE',

    ORDER_ASSESSMENT_QUESTION_REQUEST: 'ORDER_ASSESSMENT_QUESTION_REQUEST',
    ORDER_ASSESSMENT_QUESTION_SUCCESS: 'ORDER_ASSESSMENT_QUESTION_SUCCESS',
    ORDER_ASSESSMENT_QUESTION_FAILURE: 'ORDER_ASSESSMENT_QUESTION_FAILURE',

    CREATE_ASSESSMENT_RESPONSE_REQUEST: 'CREATE_ASSESSMENT_RESPONSE_REQUEST',
    CREATE_ASSESSMENT_RESPONSE_SUCCESS: 'CREATE_ASSESSMENT_RESPONSE_SUCCESS',
    CREATE_ASSESSMENT_RESPONSE_FAILURE: 'CREATE_ASSESSMENT_RESPONSE_FAILURE',

    CREATE_ASSESSMENT_VIDEO_RESPONSE_REQUEST: 'CREATE_ASSESSMENT_VIDEO_RESPONSE_REQUEST',
    CREATE_ASSESSMENT_VIDEO_RESPONSE_SUCCESS: 'CREATE_ASSESSMENT_VIDEO_RESPONSE_SUCCESS',
    CREATE_ASSESSMENT_VIDEO_RESPONSE_FAILURE: 'CREATE_ASSESSMENT_VIDEO_RESPONSE_FAILURE',
    CREATE_ASSESSMENT_VIDEO_RESPONSE_PROGRESS: 'CREATE_ASSESSMENT_VIDEO_RESPONSE_PROGRESS',

    SEND_ASSESSMENT_INVITES_REQUEST: 'SEND_ASSESSMENT_INVITES_REQUEST',
    SEND_ASSESSMENT_INVITES_SUCCESS: 'SEND_ASSESSMENT_INVITES_SUCCESS',
    SEND_ASSESSMENT_INVITES_FAILURE: 'SEND_ASSESSMENT_INVITES_FAILURE',

    DOWNLOAD_ASSESSMENT_REPORT_REQUEST: 'DOWNLOAD_ASSESSMENT_REPORT_REQUEST',
    DOWNLOAD_ASSESSMENT_REPORT_SUCCESS: 'DOWNLOAD_ASSESSMENT_REPORT_SUCCESS',
    DOWNLOAD_ASSESSMENT_REPORT_FAILURE: 'DOWNLOAD_ASSESSMENT_REPORT_FAILURE',
}
