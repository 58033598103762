import axios from 'axios';
import {SUBSCRIPTIONS_URL} from '../constants/urlConstants';
import authHeader from '../helpers/authHeader';

const fetchSubscriptions = () => {
    return axios.get(SUBSCRIPTIONS_URL, authHeader())
        .then(response => {
            return response.data;
        })
}

export const subscriptionService = {
    fetchSubscriptions,
}