import React, { Component } from 'react'
import * as Sentry from '@sentry/browser';

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error);
        this.setState({ error });
        if (process.env.NODE_ENV === 'production') {
            Sentry.withScope(scope => {
                Object.keys(errorInfo).forEach(key => {
                    scope.setExtra(key, errorInfo[key]);
                });
                Sentry.captureException(error);
            });
        }
    }

    render() {
        if (this.state.error) {
            //render fallback UI
            return (
                <p>Error occured. Try refreshing the page.</p>
                // <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>
            );
        } else {
            //when there's not an error, render children untouched
            return this.props.children;
        }
    }
}