import axios from 'axios';
import authHeader, {JSAuthHeader} from '../helpers/authHeader';
import {JOB_OPENING_URL, INTERVIEW_TEMPLATES_URL, JOB_OPENINGS_URL,JOB_APPLICATIONS_URL, JOB_OPENING_QUESTIONS_URL, JOB_CANDIDATES_URL, JOB_CANDIDATES_LIST_URL, INTERVIEW_INVITES_URL, JOB_FUNCTIONS_URL, JOB_OPENING_FILTERS_URL, JOB_OPENING_REVIEWERS_URL, RESPONSE_URL, INTERACTIONS_URL, INTERVIEW_SESSIONS_DOWNLOAD_URL, GENERIC_INTERVIEW_URL, PUBLIC_JOB_CANDIDATES_URL} from '../constants/urlConstants';

const createJobApplication = job_opening_id => {
    let job_application = {'job_opening': job_opening_id};
    return axios.post(JOB_APPLICATIONS_URL, job_application, JSAuthHeader())
            .then(response=>{
                return response.data
            })
}

const updateJSJobApplication = (data, job_application_uid) => {
    return axios.patch(JOB_APPLICATIONS_URL + job_application_uid +'/', data , JSAuthHeader())
            .then(response=>{
                return response.data
            })
}


const updateJobApplication = (data, job_application_uid) => {
    return axios.patch(JOB_APPLICATIONS_URL + job_application_uid +'/', data , authHeader())
            .then(response=>{
                return response.data
            })
}

const fetchPreScreeningQuestions = job_application_uid =>{
    return axios.get(INTERACTIONS_URL + '?job_application_id=' + job_application_uid,JSAuthHeader())
            .then(response=>{
                return response.data
            })
}

const createResponse = (question_id, value, job_opening_id) =>{
    let post_data = { interaction: question_id, transcript: 'default transcript', upload_status:'completed', value:value, job_opening_id: job_opening_id}
    return axios.post(RESPONSE_URL, post_data, JSAuthHeader())
            .then(response=>{
                return response.data
            })
}

const createVideoResponse = (question_id, value, job_opening_id, status, data) => {
    let post_data = { interaction: question_id, transcript: 'default transcript', upload_status:status, value:value, job_opening_id: job_opening_id, ...data}
    return axios.post(RESPONSE_URL, post_data, JSAuthHeader())
            .then(response=>{
                return response.data
            })
}

const createFileResponse = (question_id, file, job_opening_id) =>{
    let formData = new FormData();
    formData.append('file',file)
    formData.append('interaction', question_id);
    formData.append('transcript', 'default transcript');
    formData.append('job_opening_id', job_opening_id)
    formData.append('upload_status', 'completed')
    return axios.post(RESPONSE_URL, formData, JSAuthHeader({'Content-Type': 'multipart/form-data'}))
            .then((response)=>{
                return response.data
            })
}

const createJobOpening = (title, job_function, description, required_skills, location) => {
    return axios.post(JOB_OPENINGS_URL, {title, job_function, description, required_skills, location}, authHeader())
            .then(response=>{
                return response.data
            })
}

const createInterviewFromTemplate = (template_id) => {
    return axios.post(INTERVIEW_TEMPLATES_URL + template_id + '/clone/', {}, authHeader())
            .then(response=>{
                return response.data
            })
}

const updateJobOpening = (job_opening_id, opening_fields) => {
    return axios.patch(JOB_OPENINGS_URL + job_opening_id + '/', opening_fields, authHeader())
            .then(response=>{
                return response.data
            })
}

const addJobOpeningReviewers = (job_opening_id, user_id) => {
    return axios.post(JOB_OPENING_REVIEWERS_URL, {job_opening:job_opening_id, user:user_id}, authHeader())
    .then(response=>{
        return response.data
    })
}

const createJobOpeningQuestion = (question_title, question_type, question_description, job_opening_id, question_range, question_choices, media_type, answer_key, positive_score, negative_score, is_mandatory) => {
    return axios.post(JOB_OPENING_QUESTIONS_URL, {question_title, question_type, question_description, job_opening:job_opening_id, question_range, question_choices, media_type, answer_key, positive_score, negative_score, is_mandatory}, authHeader())
            .then(response=>response.data)
}

const sendInvites = (data) => {
    return axios.post(INTERVIEW_INVITES_URL, [...data], authHeader())
                .then(response=>response.data)
}

const updateJobDescription = (description, opening_id) => {
    return axios.patch(JOB_OPENINGS_URL + opening_id + '/', {description}, authHeader())
            .then(response=>{
                return response.data
            })
}

const fetchJobOpening = (job_uid) => {
    return axios.get(JOB_OPENING_URL + job_uid + '/', authHeader())
            .then(response=>{
                return response.data;
            })
}

const fetchGenericInterview = (job_uid) => {
    return axios.get(GENERIC_INTERVIEW_URL + job_uid + '/', authHeader())
            .then(response=>{
                return response.data;
            })
}

const fetchMoreJobCandidates = (url) => {
    return axios.get(process.env.NODE_ENV === 'production' ? url.replace('http://', 'https://') : url, authHeader())
        .then(response => {
            return response.data;
        })
}

const fetchJobCandidates = (job_uid, filters, decision_status) => {
    return axios.get(JOB_CANDIDATES_URL + '?job_uid=' + job_uid, authHeader({filters, decision_status}))
        .then(response => {
            return response.data;
        })
}

const fetchJobCandidatesList = (job_uid) => {
    return axios.get(JOB_CANDIDATES_LIST_URL + job_uid + '/', authHeader())
        .then(response => {
            return response.data;
        })
}

const downloadInterviewSessionsCSV = (job_uid) => {
    return axios.get(INTERVIEW_SESSIONS_DOWNLOAD_URL + job_uid + '/', authHeader())
        .then(response => {
            return response.data;
        })
}

const fetchJobCandidate = (job_uid, profile_uid) => {
    return axios.get(JOB_CANDIDATES_URL + '?job_uid=' + job_uid + '&profile_uid=' + profile_uid, authHeader())
        .then(response => {
            return response.data;
        })
}

const fetchPublicJobCandidate = (job_uid, profile_uid) => {
    return axios.get(PUBLIC_JOB_CANDIDATES_URL + '?job_uid=' + job_uid + '&profile_uid=' + profile_uid, authHeader())
        .then(response => {
            return response.data;
        })
}

const fetchJobOpeningsById = (job_opening_uid) => {
    return axios.get(JOB_OPENINGS_URL + job_opening_uid +'/', authHeader())
            .then(response=>{
                return response.data;
            })
}

const fetchJobOpenings = () => {
    return axios.get(JOB_OPENINGS_URL, authHeader({fields: 'id,uid,title,status,updated_at,live_date,location'}))
            .then(response=>{
                return response.data;
            })
}

const fetchJobFunctions = () => {
    return axios.get(JOB_FUNCTIONS_URL, authHeader())
            .then(response=>{
                return response.data;
            })
}

const fetchJobOpeningFilters = (job_uid) => {
    return axios.get(JOB_OPENING_FILTERS_URL, authHeader({'job_uid':job_uid}))
            .then(response=>{
                return response.data;
            })
}

const updateQuestion = (job_opening_question_id, question_title, question_type, question_description, question_range, question_choices, media_type, answer_key, positive_score, negative_score, is_mandatory) => {
    return axios.patch(JOB_OPENING_QUESTIONS_URL + job_opening_question_id + '/', {question_title, question_type, question_description, question_range, question_choices, media_type, answer_key, positive_score, negative_score, is_mandatory}, authHeader())
            .then(response=>{
                return response.data
            })
}

const orderQuestion = (job_opening_question_id, job_opening_id, oldIndex, newIndex) => {
    return axios.patch(JOB_OPENINGS_URL + job_opening_id + '/qorder/', {job_opening_question_id, 'old_index':oldIndex, 'new_index':newIndex}, authHeader())
            .then(response=>{
                return response.data
            })
}

const deleteQuestion = (job_opening_question_id) => {
    return axios.delete(JOB_OPENING_QUESTIONS_URL + job_opening_question_id + '/', authHeader())
            .then(response=>{
                console.log(response.data)
                return response.data
            })
}

export const openingService = {
    createJobApplication,
    updateJobApplication,
    updateJSJobApplication,
    createFileResponse,
    createResponse,
    createVideoResponse,
    fetchPreScreeningQuestions,
    fetchGenericInterview,
    fetchJobOpening,
    fetchJobOpenings,
    fetchJobOpeningFilters,
    fetchJobCandidates,
    fetchJobCandidate,
    fetchPublicJobCandidate,
    createJobOpening,
    updateJobOpening,
    createJobOpeningQuestion,
    updateJobDescription,
    fetchJobFunctions,
    fetchJobCandidatesList,
    fetchMoreJobCandidates,
    updateQuestion,
    deleteQuestion,
    orderQuestion,
    fetchJobOpeningsById,
    sendInvites,
    addJobOpeningReviewers,
    downloadInterviewSessionsCSV,
    createInterviewFromTemplate,
}
