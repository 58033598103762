import {templateService} from '../services';
import {alertActions, userActions} from '../actions';
import { templateConstants } from '../constants';

const fetchCategories = () => {
    return dispatch => {
        dispatch(request());
        templateService.fetchCategories()
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: templateConstants.FETCH_CATEGORIES_REQUEST} }
        function success(categories) { return { type: templateConstants.FETCH_CATEGORIES_SUCCESS, categories } }
        function failure(error) { return { type: templateConstants.FETCH_CATEGORIES_FAILURE, error } }
    }
}

const fetchInterviewTemplates = () => {
    return dispatch => {
        dispatch(request());
        templateService.fetchInterviewTemplates()
            .then(data=>{
                dispatch(success(data));
                dispatch(alertActions.clear());
            })
            .catch(error=>{
                dispatch(failure(error.response.data));
                dispatch(alertActions.error(error.response.data));
                if(error.response.status === 401){
                    dispatch(userActions.signOut());
                }
            })
        function request() { return { type: templateConstants.FETCH_TEMPLATES_REQUEST} }
        function success(templates) { return { type: templateConstants.FETCH_TEMPLATES_SUCCESS, templates } }
        function failure(error) { return { type: templateConstants.FETCH_TEMPLATES_FAILURE, error } }
    }
}

export const templateActions = {
    fetchCategories,
    fetchInterviewTemplates,
}